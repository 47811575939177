import { useContext } from "react";
import { ThemeContext } from "../../context/theme.js";
import { NavigationDrawerProvider } from "../../context/navigationDrawer";
import Header from "../Header";
import NavDrawer from "../NavDrawer/index.js";
import Notification from "../Notification/index.js";

function layout({ children }) {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div
      className={`w-screen h-screen overflow-y-scroll ${
        darkMode ? "dark bg-[#1e1e1e]" : "bg-white"
      }`}
    >
      <NavigationDrawerProvider>
        <Notification
          message={
            <div className="bg-[#1BFED7] text-black text-sm xs:text-xs p-2">
              Stay liquid during governance. Click{" "}
              <a
                className="underline"
                href="https://app.folks.finance/algo-liquid-governance?ref=algomint"
                target="blank"
              >
                here
              </a>{" "}
              to to participate
            </div>
          }
        />
        <Header />
        <NavDrawer />
      </NavigationDrawerProvider>
      <div className="content xs:mx-8 sm:mx-8">{children}</div>
    </div>
  );
}

export default layout;
