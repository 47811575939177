import algosdk from "algosdk";
import { fetchWalletDetails } from "./fetchWalletDetails";
import { fetchContractDetails } from "./fetchContractDetails";

export const IMG = (imgName, folderPath) => {
  return require(`../images/${folderPath}/${imgName}`);
};
export const truncate = (str, length) => {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  } else return str;
};

export const isTestnet = () => process.env.REACT_APP_NETWORK === "testnet";
export const isMainnet = () => process.env.REACT_APP_NETWORK === "mainnet";
export const isLocalhost = () => window.location.origin.includes("localhost:");
export const isExtensionWallet = (walletName) => {
  const extensionWalletsList = ["AlgoSigner"];
  return extensionWalletsList.find((wallet) => wallet == walletName);
};
export const isExtensionInstalled = (walletName) => {
  if (
    typeof window == "undefined" ||
    window[walletName == "AlgoSigner" ? "algorand" : walletName] === undefined
  ) {
    return false;
  } else {
    return true;
  }
};

export const getASABalance = async ({ address, assetId, txnType }) => {
  const connectedWalletDetails = await fetchWalletDetails(address);
  let payASADetails = connectedWalletDetails.assets.find((asset) => {
    return asset["asset-id"] === assetId;
  });
  if (payASADetails) {
    return { ASABalance: payASADetails.amount, txnType: txnType };
  } else {
    return { ASABalance: 0, txnType: txnType };
  }
};

export const getContractDetails = async (contractAddress) => {
  try {
    const response = await fetchContractDetails(contractAddress);
    if (response) {
      const { address, amount: algoBalance, assets } = response;

      for (let i = 0; i < assets.length; i++) {
        assets[i].amount = algosdk.microalgosToAlgos(assets[i].amount);
      }

      return { address, algoBalance, assets };
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
  }
};
