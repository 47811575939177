import React from "react";

function TransactButton({ title, onClickHandler, classes, disabled }) {
  return (
    <button
      className={`rounded-xl bg-[#1BFED7] cursor-pointer w-full h-12 bg-slate-100 hover:opacity-75 text-sm xs:text-xs ${classes}`}
      disabled={disabled}
      onClick={() => {
        onClickHandler();
      }}
    >
      {title}
    </button>
  );
}

export default TransactButton;
