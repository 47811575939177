import React, { useContext } from "react";
import { AssetSelector } from "../AssetSelector";
import { SwapAssetsContext } from "../../context/swapAssets";
import SwitchAssetButton from "../SwitchAssetButton";
import AmountInput from "../AmountInput";

export default function swapAssetsSelector({
  payAssetsList,
  receiveAssetsList,
  onPayAssetSelectHandler,
  onReceiveAssetSelectHandler,
  resetInputs,
  setResetInputs,
  setPayAssetsOptions,
  setReceiveAssetOptions,
  maxValue,
  setMaxValue,
  minValue,
  setMinValue,
  payAmount,
  receiveAmount,
  payAmountChangeHandler,
  receiveAmountChangeHandler,
  selectedPayAssetRef,
  selectedReceiveAssetRef,
}) {
  const { swapAssets } = useContext(SwapAssetsContext);
  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col space-y-2">
        <AssetSelector
          title={"Select Asset"}
          label={"FROM"}
          options={payAssetsList}
          updateContextHandler={async (assetInfo) => await onPayAssetSelectHandler(assetInfo)}
          resetInputs={resetInputs}
          setResetInputs={setResetInputs}
          ref={selectedPayAssetRef}
        />
        <AmountInput
          onChangeAmount={payAmountChangeHandler}
          amount={payAmount}
          maxValue={maxValue}
          minValue={minValue}
          setMaxValue={setMaxValue}
          setMinValue={setMinValue}
          enableValidation={true}
          disableInputField={!swapAssets.pay.name ? true : false}
          category={"pay"}
        />
      </div>
      <div>
        <SwitchAssetButton
          payAssetsList={payAssetsList}
          receiveAssetsList={receiveAssetsList}
          setPayAssetsOptions={setPayAssetsOptions}
          setReceiveAssetOptions={setReceiveAssetOptions}
          payAmountChangeHandler={payAmountChangeHandler}
          selectedPayAssetRef={selectedPayAssetRef}
          selectedReceiveAssetRef={selectedReceiveAssetRef}
          payAmount={payAmount}
          disable={!swapAssets.pay.name || !swapAssets.receive.name ? true : false}
        />
      </div>
      <div className={`flex flex-col space-y-2`}>
        <AssetSelector
          title={"Select Asset"}
          label={"TO"}
          options={receiveAssetsList}
          updateContextHandler={async (assetInfo) => await onReceiveAssetSelectHandler(assetInfo)}
          resetInputs={resetInputs}
          setResetInputs={setResetInputs}
          ref={selectedReceiveAssetRef}
        />
        <AmountInput
          onChangeAmount={receiveAmountChangeHandler}
          amount={receiveAmount}
          maxValue={maxValue}
          minValue={minValue}
          setMaxValue={setMaxValue}
          setMinValue={setMinValue}
          enableValidation={true}
          disableInputField={!swapAssets.receive.name ? true : false}
          category={"receive"}
        />
      </div>
    </div>
  );
}
