import initiateIndexerClient from "./indexerClient";
import algosdk from "algosdk";
import axios from "axios";

export const checkMinimumAlgos = async ({ address, pay, receive }) => {
  // if account only holds ALGOs, min balance is 0.1 ALGOs
  // if there are ASAs, required min is 0.1 ALGOs * (no. of ASA it holds + 1)
  // consider application opt-in calculation. More info in fetchMinBalance.

  let numberOfASAToOpt = 0;
  if (pay && !pay.isAssetOpted) {
    numberOfASAToOpt += 1;
  }
  if (receive && !receive.isAssetOpted) {
    numberOfASAToOpt += 1;
  }

  const minBalance = await fetchMinBalance(address, numberOfASAToOpt);

  return algosdk.microalgosToAlgos(parseInt(minBalance));
};

async function fetchMinBalance(address, numberOfASAToOpt) {
  const localByteMultiplier = 50000;
  const localUintMultiplier = 28500;
  const baseFee = 100000;

  try {
    let minBalance;
    if (process.env.REACT_APP_NETWORK === "sandnet-v1") {
      const indexerClient = await initiateIndexerClient();

      const response = await indexerClient.lookupAccountByID(address).do();

      const localSchema = response["apps-total-schema"];
      const totalAppsOpted = response["total-apps-opted-in"];
      const totalASAOpted = response["total-assets-opted-in"];

      // response['min-balance'] is available to use,
      // below equation is used for documentation.

      let applicationOffset = 0; // below are all in microAlgos
      let asaOffset = 0;
      let algoOffset = 100000;
      let txnFee = 1000;

      if (totalAppsOpted > 0) {
        const numBytes = localSchema["num-byte-slice"];
        const numUint = localSchema["num-uint"];

        applicationOffset =
          baseFee + numBytes * localByteMultiplier + numUint * localUintMultiplier;
      }

      if (totalASAOpted > 0) {
        asaOffset = 100000 * totalASAOpted;
      }

      // calculates the offset of the txn the user is about to executed
      const futureTxn = txnFee + numberOfASAToOpt * algoOffset;

      minBalance = algoOffset + asaOffset + applicationOffset + futureTxn + txnFee;
    } else {
      let response = await axios.get("https://mainnet-idx.algonode.cloud/v2/accounts/" + address);
      const localSchema = response.data["apps-total-schema"];
      const totalAppsOpted = response.data["total-apps-opted-in"];
      const totalASAOpted = response.data["total-assets-opted-in"];

      let applicationOffset = 0; // below are all in microAlgos
      let asaOffset = 0;
      let algoOffset = 100000;
      let txnFee = 1000;

      if (totalAppsOpted > 0) {
        const numBytes = localSchema["num-byte-slice"];
        const numUint = localSchema["num-uint"];

        applicationOffset =
          baseFee + numBytes * localByteMultiplier + numUint * localUintMultiplier;
      }

      if (totalASAOpted > 0) {
        asaOffset = 100000 * totalASAOpted;
      }

      // calculates the offset of the txn the user is about to executed
      const futureTxn = txnFee + numberOfASAToOpt * algoOffset;

      minBalance = algoOffset + asaOffset + applicationOffset + futureTxn + txnFee;
    }

    return minBalance;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export { fetchMinBalance };
