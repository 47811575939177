import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import MaxButton from "../MaxButton";
import {
  MAX_VALUE,
  MIN_VALUE,
  CREATOR_ADDRESS,
  POOL_APP_ID,
  ASSET_IDS,
  DECIMAL_PLACE,
} from "../../constants/constants";
import { SwapAssetsContext } from "../../context/swapAssets";
import { poolBalances } from "../rcvAmount";
function AmountInput({
  disableInputField,
  onChangeAmount,
  amount,
  setMaxValue,
  setMinValue,
  maxValue,
  minValue,
  enableValidation,
  category,
}) {
  const [poolMaxValue, setPoolMaxValue] = useState(0);
  const { swapAssets, basketType } = useContext(SwapAssetsContext);
  const decimalPlace = DECIMAL_PLACE[basketType];

  let POOL_APP_ID_QUERY;

  if (
    swapAssets.pay.name === "goUSD" ||
    swapAssets.pay.name === "goETH" ||
    swapAssets.pay.name === "goBTC"
  ) {
    POOL_APP_ID_QUERY = swapAssets.receive.name;
  } else {
    POOL_APP_ID_QUERY = swapAssets.pay.name;
  }

  // console.log(POOL_APP_ID_QUERY);

  const maxAmountChangeHandler = async () => {
    const { balanceA, balanceB } = await poolBalances(
      CREATOR_ADDRESS,
      POOL_APP_ID[POOL_APP_ID_QUERY],
    );
    if (!balanceA) {
      setPoolMaxValue(0);
    } else if (swapAssets.pay.assetID != ASSET_IDS[basketType]) {
      setPoolMaxValue(1000000000000);
    } else {
      setPoolMaxValue(balanceB / decimalPlace);
    }
    if (POOL_APP_ID_QUERY == "WUSDT" || POOL_APP_ID_QUERY == "WUSDC") {
      setPoolMaxValue(1000000000000);
    }
  };

  useEffect(() => {
    const maximumValue = async () => {
      await maxAmountChangeHandler();
    };
    if (swapAssets.pay.name || swapAssets.receive.name) {
      maximumValue();
    }
  }, [swapAssets]);

  const validateInput = (value) => {
    if (!enableValidation) {
      onChangeAmount(parseFloat(value));
      return;
    }
    if (parseFloat(value) <= MIN_VALUE) {
      setMinValue(true);
    } else {
      setMinValue(false);
    }
    if (parseFloat(value) >= poolMaxValue) {
      setMaxValue(true);
    } else {
      setMaxValue(false);
    }
    onChangeAmount(parseFloat(value));
  };
  return (
    <>
      <div
        className={`asset-amount-input-wrapper ${
          disableInputField ? "hidden" : ""
        } h-12 relative flex items-center rounded-md shadow-md border dark:border-white focus:outline-double dark:bg-[#232328] bg-white`}
      >
        <div className="ml-4">
          <NumericFormat
            placeholder="0.00"
            className="focus:outline-none h-full font-semibold xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md  dark:text-white dark:bg-[#232328]"
            type="text"
            value={amount}
            isnumericstring="true"
            onValueChange={(values) => {
              const { value } = values;
              validateInput(value);
            }}
            decimalScale={6}
            thousandSeparator={true}
            disabled={disableInputField}
          />
        </div>
        <div className="absolute bottom-1.5 right-2">
          <MaxButton
            onChangeAmount={onChangeAmount}
            maxValue={maxValue}
            category={category}
            poolMaxValue={poolMaxValue}
          />
        </div>
      </div>
      <div className="text-xs font-semibold px-3 pt-2 text-red-500">
        {maxValue === true && category === "pay"
          ? `* Maximum  amount is ${poolMaxValue.toLocaleString()} ${swapAssets.pay.name}`
          : minValue === true &&
            category === "pay" &&
            `* Minimum amount is ${MIN_VALUE[basketType].toLocaleString(undefined, {
              minimumFractionDigits: 3,
            })} ${swapAssets.pay.name}`}
      </div>
    </>
  );
}

export default AmountInput;
