import initiateAlgodClient from "../../utils/algodClient";

import axios from "axios";

function isqrt(value) {
  if (value < 2n) {
    return value;
  }

  if (value < 16n) {
    return BigInt(Math.floor(Math.sqrt(Number(value))));
  }

  let x1;
  if (value < 1n << 52n) {
    x1 = BigInt(Math.floor(Math.sqrt(Number(value)))) - 3n;
  } else {
    x1 = (1n << 52n) - 2n;
  }

  let x0 = -1n;
  while (x0 !== x1 && x0 !== x1 - 1n) {
    x0 = x1;
    x1 = (value / x0 + x0) >> 1n;
  }
  return x0;
}

export async function readGlobalState(client, addr, appId) {
  const output = {};
  const accountInfoResponse = await client.accountInformation(addr).do();
  for (let i = 0; i < accountInfoResponse["created-apps"].length; i++) {
    if (accountInfoResponse["created-apps"][i].id == appId) {
      for (
        let n = 0;
        n < accountInfoResponse["created-apps"][i]["params"]["global-state"].length;
        n++
      ) {
        const variable = accountInfoResponse["created-apps"][i]["params"]["global-state"][n];
        const name = Buffer.from(variable.key, "base64").toString();
        output[name] = variable.value;
      }
    }
  }
  return output;
}

async function get_d(balanceA, balanceB) {
  const liqA = BigInt(balanceA);
  const liqB = BigInt(balanceB);
  const amp = BigInt(10000);
  const precision = BigInt(1000);

  const tokens_total = liqA + liqB;
  const S = tokens_total;
  if (S === BigInt(0)) {
    return [S, 0];
  }

  let D = S;
  const Ann = amp * BigInt(4);

  let i = 0;
  let Dprev = D;
  while (i < 64) {
    i += 1;
    let D_P = D * D * D;
    D_P /= liqA * liqB * BigInt(4);
    Dprev = D;
    const numerator = D * ((Ann * S) / precision + D_P * BigInt(2));
    const divisor = ((Ann - precision) * D) / precision + BigInt(3) * D_P;
    D = numerator / divisor;
    if (D > Dprev) {
      if (D - Dprev <= BigInt(1)) {
        break;
      }
    } else if (Dprev - D <= BigInt(1)) {
      break;
    }
  }
  return D;
}

async function get_y(d, newBalance) {
  const liqOther = BigInt(newBalance);
  const amplifier = BigInt(10000);
  const inv = BigInt(await d); //Update value after running getInvariant
  const precision = BigInt(1000);

  const S = liqOther;
  const D = inv;
  const A = amplifier;
  const P = liqOther;
  const Ann = A * BigInt(4);

  const b = S + (D * precision) / Ann;
  const c = (precision * (D * D * D)) / (BigInt(4) * P * Ann);

  const a_q = 1n;
  //re-arrange this so it gives a positive number
  // const b_q = D - b;

  let b_q;
  if (b < D) {
    b_q = D - b;
  } else {
    b_q = b - D;
  }
  const c_q = c;

  const delta = b_q * b_q + 4n * a_q * c_q;
  return (b_q + isqrt(delta)) / (2n * a_q);
}

/**
 *
 * @description Calculates the expected output amount for X amount added
 * @param balanceA LP pool balance of token A
 * @param balanceB LP pool balance of token B
 * @param newBalance Balance + added amount
 * @param otherLiq balance of the token being received
 * @returns Expected receive amount
 */
export async function getReceiveAmount(balanceA, balanceB, newBalance, otherLiq) {
  let otherliq = BigInt(otherLiq);
  let d = get_d(balanceA, balanceB);
  let y = await get_y(d, newBalance);

  let rcv = otherliq - y;
  let fee = rcv * BigInt(4);
  let fees = Number(rcv) * 0.0001;
  let rcv_amount = Math.floor(Number(rcv) - 1);

  return rcv_amount;
}

//Calculates amount the user should receive, returns both amount with fees and amount without.
// export async function rcvAmountMint(creatorAddr, poolId, sentAmount) {
//   let balanceA;
//   let balanceB;
//   if (process.env.REACT_APP_NETWORK === "sandnet-v1") {
//     const algodClient = await initiateAlgodClient();
//     const poolState = await readGlobalState(algodClient, creatorAddr, poolId);
//     const balanceA_ = poolState["A"];
//     const balanceB_ = poolState["B"];
//     balanceA = balanceA_.uint;
//     balanceB = balanceB_.uint;
//   } else {
//     let response = await axios.get(
//       `https://node.testnet.algoexplorerapi.io/v2/applications/${poolId}`,
//     );
//     let globalState = response.data.params["global-state"];
//     let length = globalState.length;
//     const balanceAKey = "QQ==";
//     const balanceBKey = "Qg==";
//     for (let i = 0; i < globalState.length; i++) {
//       if (globalState[i].key === balanceAKey) {
//         balanceA = globalState[i].value.uint;
//       }
//     }
//     for (let i = 0; i < globalState.length; i++) {
//       if (globalState[i].key === balanceBKey) {
//         balanceB = globalState[i].value.uint;
//       }
//     }
//   }

//   let newBalance;
//   if (sentAmount > balanceB) {
//     newBalance = balanceA + balanceB;
//   } else {
//     newBalance = balanceA + sentAmount;
//   }
//   const returnAmount = await getReceiveAmount(balanceA, balanceB, newBalance, balanceB);

//   return returnAmount;
// }

//Calculates amount the user should receive, returns both amount with fees and amount without.
// export async function rcvAmountRedeem(creatorAddr, poolId, sentAmount) {
//   let balanceA;
//   let balanceB;
//   if (process.env.REACT_APP_NETWORK === "sandnet-v1") {
//     const algodClient = await initiateAlgodClient();
//     const poolState = await readGlobalState(algodClient, creatorAddr, poolId);
//     const balanceA_ = poolState["A"];
//     const balanceB_ = poolState["B"];
//     balanceA = balanceA_.uint;
//     balanceB = balanceB_.uint;
//   } else {
//     let response = await axios.get(
//       `https://node.testnet.algoexplorerapi.io/v2/applications/${poolId}`,
//     );
//     let globalState = response.data.params["global-state"];
//     let length = globalState.length;
//     const balanceAKey = "QQ==";
//     const balanceBKey = "Qg==";
//     for (let i = 0; i < globalState.length; i++) {
//       if (globalState[i].key === balanceAKey) {
//         balanceA = globalState[i].value.uint;
//       }
//     }
//     for (let i = 0; i < globalState.length; i++) {
//       if (globalState[i].key === balanceBKey) {
//         balanceB = globalState[i].value.uint;
//       }
//     }
//   }

//   let newBalance;
//   if (sentAmount > balanceA) {
//     newBalance = balanceB + balanceA;
//   } else {
//     newBalance = balanceB + sentAmount;
//   }
//   const returnAmount = await getReceiveAmount(balanceA, balanceB, newBalance, balanceA);

//   return returnAmount;
// }

//Gets the balance of each pool for MAX
export async function poolBalances(creatorAddr, poolId) {
  let balanceA;
  let balanceB;
  if (process.env.REACT_APP_NETWORK === "sandnet-v1") {
    const algodClient = await initiateAlgodClient();
    const poolState = await readGlobalState(algodClient, creatorAddr, poolId);
    const balanceA_ = poolState["A"];
    const balanceB_ = poolState["B"];
    balanceA = balanceA_.uint;
    balanceB = balanceB_.uint;
  } else {
    let response = await axios.get(
      `https://mainnet-idx.algonode.cloud/v2/applications?application-id=${poolId}`,
    );

    let globalState = response.data.applications[0].params["global-state"];
    const balanceAKey = "QQ==";
    const balanceBKey = "Qg==";
    for (let i = 0; i < globalState.length; i++) {
      if (globalState[i].key === balanceAKey) {
        balanceA = globalState[i].value.uint;
      }
    }
    for (let i = 0; i < globalState.length; i++) {
      if (globalState[i].key === balanceBKey) {
        balanceB = globalState[i].value.uint;
      }
    }
  }
  return { balanceA: balanceA, balanceB: balanceB };
}
