import React, { useContext } from "react";
import { MdWbSunny, MdOutlineDarkMode } from "react-icons/md";
import { ThemeContext } from "../../context/theme";

export default function DarkModeButton({ onClickHandler }) {
  const { darkMode } = useContext(ThemeContext);
  return (
    <button
      className="darkMode-btn border flex justify-center items-center  hover:bg-[#1BFED7] hover:text-black h-9.5 xs:h-9 sm:h-9 md:h-9 w-16 border-[#1BFED7] rounded-full text-[#1BFED7] "
      onClick={() => {
        onClickHandler();
      }}
    >
      {!darkMode ? (
        <MdOutlineDarkMode
          size="24"
          className="transition ease-in-out hover:scale-125 duration-300 xs:mx-2 text-black"
        />
      ) : (
        <MdWbSunny
          size="24"
          className="transition ease-in-out hover:scale-125 hover:text-black duration-300 xs:mx-2 text-[#1BFED7]"
        />
      )}
    </button>
  );
}
