import React, { useState, useEffect } from "react";
import { navigationItems } from "../../constants/navigationItems";
import "../NavItem/NavItem.css";
import { BsArrowUpRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const NavItems = ({ toggleState }) => {
  const [navItems, setNavItems] = useState(navigationItems);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, []);

  useEffect(() => {
    if (isMobileScreen) {
      setNavItems((navItems) => {
        return navItems.filter(({ title }) => {
          return title !== "...";
        });
      });
    } else {
      setNavItems(navigationItems);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    if (process.env.REACT_APP_NETWORK !== "testnet") {
      setNavItems((navItems) => {
        return navItems.filter(({ title }) => {
          return title !== "FAUCET";
        });
      });
    }
  }, []);
  return (
    <div>
      <ul
        className={`${
          !toggleState && "flex xs:flex-col sm:flex-col  justify-around p-4"
        } menu-item md:inline-flex `}
      >
        {navItems.map(
          (
            { title, link, isExternalLink, displayArrowIcon, component },
            index
          ) => {
            return (
              <li
                key={index}
                className={`flex text-base ${
                  component ? "" : "nav-item"
                }  xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md mx-2 py-2 text-black dark:text-white cursor-pointer`}
              >
                {component ? (
                  component
                ) : (
                  <>
                    {" "}
                    {isExternalLink ? (
                      <a href={link} target={"_blank"}>
                        {title}
                      </a>
                    ) : (
                      <NavLink
                        to={link}
                        className={({ isActive }) =>
                          isActive ? "nav-item-selected" : undefined
                        }
                      >
                        {" "}
                        {title}
                      </NavLink>
                    )}
                    {isExternalLink && displayArrowIcon ? (
                      <span className="px-1 mt-1">
                        <BsArrowUpRight />
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default NavItems;
