import React, { useEffect } from "react";
import Button from "../Button";
import NavItems from "../NavItem";
import { useContext } from "react";
import "./Header.css";
import AlgomintLogo from "../../images/icons/algomint-logo.svg";
import DisconnectWallet from "../DisconnectWallet";
import { NavigationDrawerContext } from "../../context/navigationDrawer";
import { WalletDetailsContext } from "../../context/walletDetails";
import { fetchWalletDetails } from "../../utils/fetchWalletDetails";
import { ThemeContext } from "../../context/theme";
import DarkModeButton from "../DarkModeButton";
import { useWallet } from "@txnlab/use-wallet";

export default function Header() {
  const { toggle, changeDrawerState } = useContext(NavigationDrawerContext);
  const { changeTheme } = useContext(ThemeContext);
  const { walletDetails, setWalletDetails } = useContext(WalletDetailsContext);
  const { providers } = useWallet();

  const navItemHander = () => (toggle ? changeDrawerState() : null);

  const toggleMenu = (x) => {
    changeDrawerState();
    x.currentTarget.classList.toggle("change");
  };

  useEffect(() => {
    if (providers) {
      providers.map(async (walletProvider) => {
        if (walletProvider.isActive && walletProvider.isConnected) {
          let walletDetails = await fetchWalletDetails(
            walletProvider.accounts[0]["address"]
          );
          setWalletDetails({
            ...walletDetails,
            isConnected: walletProvider.isConnected,
            isConnectWalletModalOpen: false,
            didConnectWalletFail: {
              failed: false,
              provider: "",
            },
            provider: walletProvider.accounts[0]["providerId"],
            address: walletProvider.accounts[0]["address"],
            balance: walletDetails.amount,
            assets: walletDetails.assets,
            isOfflineAccount: walletDetails.isOfflineAccount, //since algo account has offline and online types
            minBalance: null,
            metaData: walletProvider,
          });
        }
      });
    }
  }, [providers]);
  return (
    <header>
      <nav
        className={`nav flex gap-6 xs:max-h-28 xs:min-h-28 sm:max-h-28 sm:min-h-28 md:max-h-28 md:min-h-28 lg:max-h-28 lg:min-h-28 xl:max-h-28 xl:min-h-28 2xl:max-h-28 2xl:min-h-28 xs:px-8 sm:px-8 md:px-16 lg:px-4 xl:px-4 2xl:px-4 justify-between ${
          !toggle && "nav-bg"
        }`}
      >
        <div className="flex items-center">
          <a href="https://www.algomint.io" target="_blank">
            <img
              src={AlgomintLogo}
              className="md:hidden lg:hidden xl:hidden 2xl:hidden h-12 w-12"
              alt="Algomint logo"
            />
          </a>

          <div className="text-white xs:hidden sm:hidden md:block">
            <NavItems toggleState={toggle} navItemHandler={navItemHander} />
          </div>
        </div>
        <div className="flex space-x-4 md:flex-row  flex-row justify-center xs:hidden sm:hidden">
          {walletDetails.isConnected ? (
            <DisconnectWallet
              onClickDisconnectHandler={() => {
                walletDetails.metaData.disconnect();
                setWalletDetails({
                  ...walletDetails,
                  isConnected: false,
                  isConnectWalletModalOpen: false,
                  didConnectWalletFail: {
                    failed: false,
                    provider: "",
                  },
                  provider: "",
                  address: null,
                  balance: null,
                  assets: [],
                  isOfflineAccount: false, //since algo account has offline and online types
                  minBalance: null,
                  metaData: {},
                });
              }}
            />
          ) : (
            <Button
              name="Connect"
              onClickHandler={() => {
                setWalletDetails({
                  ...walletDetails,
                  isConnectWalletModalOpen: true,
                });
              }}
            />
          )}
          <DarkModeButton onClickHandler={changeTheme} />
        </div>
        <div className="md:hidden flex lg:hidden xl:hidden 2xl:hidden absolute right-4 text-white menu-icon">
          <div className="absolute right-32">
            {walletDetails.isConnected ? (
              <DisconnectWallet
                onClickDisconnectHandler={() => {
                  walletDetails.metaData.disconnect();
                  setWalletDetails({
                    ...walletDetails,
                    isConnected: false,
                    isConnectWalletModalOpen: false,
                    didConnectWalletFail: {
                      failed: false,
                      provider: "",
                    },
                    provider: "",
                    address: null,
                    balance: null,
                    assets: [],
                    isOfflineAccount: false, //since algo account has offline and online types
                    minBalance: null,
                    metaData: {},
                  });
                }}
              />
            ) : (
              <Button
                name="Connect"
                onClickHandler={() => {
                  setWalletDetails({
                    ...walletDetails,
                    isConnectWalletModalOpen: true,
                  });
                }}
              />
            )}
          </div>
          <div className="absolute right-14">
            <DarkModeButton onClickHandler={changeTheme} />{" "}
          </div>
          <div onClick={toggleMenu}>
            <div className="triple-bars">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
