import React, { useContext } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { WalletDetailsContext } from "../../context/walletDetails";
import { RxCross1 } from "react-icons/rx";
import { isExtensionWallet, isExtensionInstalled } from "../../utils/misc";
import { fetchWalletDetails } from "../../utils/fetchWalletDetails";
import toast from "react-hot-toast";

export default function walletConnectionModal() {
  const { providers, activeAccount } = useWallet();
  const { walletDetails, setWalletDetails } = useContext(WalletDetailsContext);

  return walletDetails.isConnectWalletModalOpen ? (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>

      <div className="absolute left-0 right-1 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="flex align-center justify-center transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white dark:bg-[#232328] dark:text-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4 xs:p-6 xs:pb-4 h-128 w-96 sm:w-full">
              {" "}
              <div className="flex justify-between ml-3">
                <div>Connect Wallet</div>
                <div>
                  {" "}
                  <RxCross1
                    className="cursor-pointer dark:text-white"
                    size={25}
                    onClick={() => {
                      setWalletDetails({
                        ...walletDetails,
                        isConnectWalletModalOpen: false,
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="wallet-options flex flex-col cursor-pointer w-full h-full">
                {/* not including exodus wallet */}
                {providers?.map((provider, index) =>
                  provider.metadata.name == "Exodus" ||
                  provider.metadata.name == "AlgoSigner" ? null : (
                    <div
                      key={"provider-" + provider.metadata.id}
                      className="flex space-x-6 p-4 dark:hover:bg-zinc-800 hover:bg-gray-100"
                      onClick={async () => {
                        if (isExtensionWallet(provider.metadata.name)) {
                          if (!isExtensionInstalled(provider.metadata.name)) {
                            return;
                          }
                        }
                        provider
                          .connect()
                          .then(async () => {
                            if (provider.isConnected) {
                              let walletDetails = await fetchWalletDetails(activeAccount.address);
                              setWalletDetails({
                                ...walletDetails,
                                isConnected: true,
                                provider: activeAccount.providerId,
                                address: activeAccount.address,
                                balance: walletDetails.amount,
                                assets: walletDetails.assets,
                                isConnectWalletModalOpen: false,
                                metaData: provider,
                                isOfflineAccount: walletDetails.isOfflineAccount,
                              });
                            }
                          })
                          .catch((err) => {
                            toast.error(<div>{JSON.stringify(err)}</div>, {
                              position: "top-right",
                              duration: 10000,
                            });
                          });
                      }}
                    >
                      <div>
                        <img src={provider.metadata.icon} alt="wallet-logo" className="h-6 w-6" />
                      </div>
                      <div className="text-lg flex-col">
                        {" "}
                        <div>{provider.metadata.name}</div>
                        {isExtensionWallet(provider.metadata.name) ? (
                          !isExtensionInstalled(provider.metadata.name) ? (
                            <div className="text-xs text-red-500">Extension not installed</div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
