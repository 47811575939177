import { fetchWalletDetails } from "./fetchWalletDetails.js";

async function isOptedIntoAsset(_addr, _assetId) {
  if (!_addr || !_assetId) return false;

  try {
    let response = await fetchWalletDetails(_addr);
    if (response) {
      let { assets } = response;
      if (!assets) {
        return false;
      }
      let isgoAssetOpted = assets.some((asset) => {
        return asset["asset-id"] === _assetId;
      });
      return isgoAssetOpted;
    }
    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
}

export { isOptedIntoAsset };
