import React, { useContext, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import algosdk from "algosdk";
import Button from "../Button";
import { goBaskets } from "../../constants/goBaskets";
import Accordion from "../Accordion";
import { AssetSelector } from "../AssetSelector";
import { SwapAssetsContext } from "../../context/swapAssets";
import { ContractContext } from "../../context/contract";
import SwapAssetsSelector from "../SwapAssetsSelector";
import TransactionCostDetails from "../TransactionCostDetails";
import TransactButton from "../TransactButton";
import { WalletDetailsContext } from "../../context/walletDetails";
import { isOptedIntoAsset } from "../../utils/assetOPT-inValidator";
import { useWallet } from "@txnlab/use-wallet";
import initiateAlgodClient from "../../utils/algodClient";
import { basketTokens } from "../../constants/basketTokens";
import isContractStatusOnline from "../../utils/isContractStatusOnline";
import { checkMinimumAlgos } from "../../utils/fetchMinBalance";
import { handleSwapTransaction } from "../../services/Transaction";
import {
  POOL_APP_ID,
  ASSET_IDS,
  CONTRACT_ADDRESS,
  TREASURY_ADDRESS,
  CREATOR_ADDRESS,
  APP_ID,
  FEE_PERCENT,
  DECIMAL_PLACE,
  POOL_CONTRACT_ADDR,
  POOL_ESCROW_ADDR,
  ESCROW_APP_ID,
  FARM_APP_ID,
} from "../../constants/constants";
import { getASABalance, getContractDetails } from "../../utils/misc";
import { fetchPoolDetails } from "../../utils/fetchPoolDetails";
import EncodeBytes from "../../utils/encodeBytes";
import Spinner from "../Spinner";
import axios from "axios";

function collapsecontainer() {
  const selectedBasketRef = useRef();
  const selectedPayAssetRef = useRef();
  const selectedReceiveAssetRef = useRef();
  const timerRef = useRef(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { swapAssets, setSwapAssets, basketType, setBasketType } = useContext(SwapAssetsContext);
  const [resetInputs, setResetInputs] = useState(false);
  const { contractDetails, setContractDetails } = useContext(ContractContext);
  const [payAssetsOptions, setPayAssetsOptions] = useState([]);
  const [receiveAssetsOptions, setReceiveAssetOptions] = useState([]);
  const [isSmartContractOnline, setisSmartContractOnline] = useState(false);
  const { walletDetails, setWalletDetails } = useContext(WalletDetailsContext);
  const [isLoading, setisLoading] = useState(false);
  const { signTransactions, sendTransactions } = useWallet();
  const [slippageRate, setSlippageRate] = useState(0.1);
  const [maxValue, setMaxValue] = useState(false);
  const [minValue, setMinValue] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const decimalPlace = DECIMAL_PLACE[basketType];

  let POOL_APP_ID_QUERY;

  if (
    swapAssets.pay.name === "goUSD" ||
    swapAssets.pay.name === "goETH" ||
    swapAssets.pay.name === "goBTC"
  ) {
    POOL_APP_ID_QUERY = swapAssets.receive.name;
  } else {
    POOL_APP_ID_QUERY = swapAssets.pay.name;
  }

  const payAmountChangeHandler = async (value) => {
    let feeQuery;
    if (
      swapAssets.pay["name"] == "goUSD" ||
      swapAssets.pay["name"] == "goETH" ||
      swapAssets.pay["name"] == "goBTC"
    ) {
      feeQuery = swapAssets.receive.name;
    } else {
      feeQuery = swapAssets.pay.name;
    }

    setPayAmount(value);

    let calculatedReceiveAmount = value * (1 - FEE_PERCENT[feeQuery]);
    setReceiveAmount(calculatedReceiveAmount);
  };

  const receiveAmountChangeHandler = async (value) => {
    let feeQuery;
    if (
      swapAssets.pay["name"] == "goUSD" ||
      swapAssets.pay["name"] == "goETH" ||
      swapAssets.pay["name"] == "goBTC"
    ) {
      feeQuery = swapAssets.receive.name;
    } else {
      feeQuery = swapAssets.pay.name;
    }

    setReceiveAmount(value);
    let calculatedpayAmount = value / (1 - FEE_PERCENT[feeQuery]);
    setPayAmount(calculatedpayAmount);
  };
  useEffect(() => {
    const recalculateAmounts = async () => {
      await payAmountChangeHandler(payAmount);
    };
    recalculateAmounts();
  }, [slippageRate]);

  useEffect(() => {
    if (isLoading) {
      toast.loading("Transaction Processing", {
        position: "top-right",
        duration: 2000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (basketType) {
      setResetInputs(true);
    }
  }, [basketType]);

  useEffect(() => {
    if (walletDetails.isConnected && swapAssets.pay.isAssetOpted) {
      //Clear any existing setInterval timer first.
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        ASABalance();
      }, 2000);
      ASABalance();
    }
    //Clear timer when component unmounts.
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, [walletDetails.isConnected, swapAssets.pay]);

  useEffect(() => {
    const checkMinAlgos = async () => {
      let address = walletDetails.address;
      let payAssetDetails = swapAssets.pay;
      let receiveAssetDetails = swapAssets.receive;
      let result = await checkMinimumAlgos({
        address,
        payAssetDetails,
        receiveAssetDetails,
      });
      setWalletDetails({
        ...walletDetails,
        minBalance: result,
      });
    };
    if (
      walletDetails.isConnected &&
      !walletDetails.isOfflineAccount &&
      swapAssets.pay.name &&
      swapAssets.receive.name
    ) {
      checkMinAlgos();
    }
  }, [walletDetails.isConnected, swapAssets]);

  useEffect(() => {
    if (isFirstLoad) {
      selectedBasketRef.current.setSelectedIndex(0);
      updateSelectedgoBasket(goBaskets[0]);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    const contractStatus = async () => {
      let isOnline = await isContractStatusOnline();
      setisSmartContractOnline(isOnline);
      return isOnline;
    };

    const fetchContractDetails = async () => {
      let contractInfo = await getContractDetails(CONTRACT_ADDRESS[basketType]);
      if (contractInfo) {
        setContractDetails({
          ...contractDetails,
          algoBalance: contractInfo.algoBalance,
          assets: contractInfo.assets,
        });
      }
    };
    if (contractStatus() && walletDetails.isConnected && basketType) {
      fetchContractDetails();
    }
  }, [walletDetails.isConnected, basketType]);

  const ASABalance = async () => {
    let payAssetBalance = await getASABalance({
      address: walletDetails.address,
      assetId: swapAssets.pay.assetID,
      txnType: "pay",
    });
    if (swapAssets.pay.assetBalance !== payAssetBalance.ASABalance) {
      setSwapAssets({
        ...swapAssets,
        pay: {
          ...swapAssets.pay,
          assetBalance: payAssetBalance.ASABalance,
        },
      });
    } else {
      return;
    }
  };

  const updateSelectedgoBasket = (assetInfo) => {
    setBasketType(assetInfo.name);
    setIsFirstLoad(true);
    setSwapAssets({
      ...swapAssets,
      pay: {
        amount: 0,
        name: null,
        isAssetOPTinRequired: false,
        assetID: null,
        isAssetOpted: false,
        blockchain: null,
        assetBalance: null,
      },
      receive: {
        amount: 0,
        name: null,
        isAssetOPTinRequired: false,
        assetID: null,
        isAssetOpted: false,
        blockchain: null,
        assetBalance: null,
      },
    });

    setPayAssetsOptions(basketTokens(assetInfo.name));
    selectedPayAssetRef.current.setSelectedIndex(null);
    selectedReceiveAssetRef.current.setSelectedIndex(null);
  };

  const updateSelectedPayAsset = async (assetInfo) => {
    if (isFirstLoad) {
      if (assetInfo.name == basketType) {
        let filteredReceiveOptionsList = payAssetsOptions.filter(
          (assets) => assets.name !== basketType,
        );
        let isPayAssetOpted;
        if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
          isPayAssetOpted = await isOptedIntoAsset(walletDetails.address, assetInfo.assetID);
        }
        setPayAssetsOptions([
          {
            name: basketType,
            assetID: ASSET_IDS[basketType],
            blockchain: "Algorand",
            isAssetOPTinRequired: true,
          },
        ]);
        setReceiveAssetOptions(filteredReceiveOptionsList);
        selectedPayAssetRef.current.setSelectedIndex(0);
        if (filteredReceiveOptionsList.length == 1) {
          selectedReceiveAssetRef.current.setSelectedIndex(0);
          let isReceiveAssetOpted;
          if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
            isReceiveAssetOpted = await isOptedIntoAsset(
              walletDetails.address,
              filteredReceiveOptionsList[0].assetID,
            );
          }
          setSwapAssets({
            ...swapAssets,
            pay: {
              name: assetInfo.name,
              amount: 0,
              assetBalance: null,
              assetID: assetInfo.assetID,
              blockchain: assetInfo.blockchain,
              isAssetOPTinRequired: assetInfo.isAssetOPTinRequired,
              isAssetOpted:
                assetInfo.isAssetOPTinRequired && walletDetails.isConnected
                  ? isPayAssetOpted
                  : assetInfo.isAssetOpted,
            },
            receive: {
              name: filteredReceiveOptionsList[0].name,
              amount: 0,
              assetBalance: null,
              assetID: filteredReceiveOptionsList[0].assetID,
              blockchain: filteredReceiveOptionsList[0].blockchain,
              isAssetOPTinRequired: filteredReceiveOptionsList[0].isAssetOPTinRequired,
              isAssetOpted:
                assetInfo.isAssetOPTinRequired && walletDetails.isConnected
                  ? isReceiveAssetOpted
                  : assetInfo.isAssetOpted,
            },
          });
        } else {
          setSwapAssets({
            ...swapAssets,
            pay: {
              name: assetInfo.name,
              amount: 0,
              assetBalance: null,
              assetID: assetInfo.assetID,
              blockchain: assetInfo.blockchain,
              isAssetOPTinRequired: assetInfo.isAssetOPTinRequired,
              isAssetOpted:
                assetInfo.isAssetOPTinRequired && walletDetails.isConnected
                  ? isPayAssetOpted
                  : assetInfo.isAssetOpted,
            },
          });
        }
      } else {
        let filteredPayOptionsList = payAssetsOptions.filter(
          (assets) => assets.name !== basketType,
        );
        let isPayAssetOpted;
        let isReceiveAssetOpted;
        if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
          isPayAssetOpted = await isOptedIntoAsset(walletDetails.address, assetInfo.assetID);
        }
        if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
          isReceiveAssetOpted = await isOptedIntoAsset(walletDetails.address, assetInfo.assetID);
        }
        setSwapAssets({
          ...swapAssets,
          pay: {
            name: assetInfo.name,
            amount: 0,
            assetBalance: null,
            assetID: assetInfo.assetID,
            blockchain: assetInfo.blockchain,
            isAssetOPTinRequired: assetInfo.isAssetOPTinRequired,
            isAssetOpted:
              assetInfo.isAssetOPTinRequired && walletDetails.isConnected
                ? isPayAssetOpted
                : assetInfo.isAssetOpted,
          },
          receive: {
            name: basketType,
            amount: 0,
            assetBalance: null,
            assetID: ASSET_IDS[basketType],
            blockchain: "Algorand",
            isAssetOPTinRequired: true,
            isAssetOpted:
              assetInfo.isAssetOPTinRequired && walletDetails.isConnected
                ? isReceiveAssetOpted
                : assetInfo.isAssetOpted,
          },
        });
        selectedReceiveAssetRef.current.setSelectedIndex(0);
        setPayAssetsOptions(filteredPayOptionsList);
        setReceiveAssetOptions([
          {
            name: basketType,
            assetID: ASSET_IDS[basketType],
            blockchain: "Algorand",
            isAssetOPTinRequired: true,
          },
        ]);
      }
      setIsFirstLoad(false);
      return;
    }
    let isPayAssetOpted;
    if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
      isPayAssetOpted = await isOptedIntoAsset(walletDetails.address, assetInfo.assetID);
    }
    setSwapAssets({
      ...swapAssets,
      pay: {
        name: assetInfo.name,
        amount: 0,
        assetBalance: null,
        assetID: assetInfo.assetID,
        blockchain: assetInfo.blockchain,
        isAssetOPTinRequired: assetInfo.isAssetOPTinRequired,
        isAssetOpted:
          assetInfo.isAssetOPTinRequired && walletDetails.isConnected
            ? isPayAssetOpted
            : assetInfo.isAssetOpted,
      },
    });
  };
  const updateSelectedReceiveAsset = async (assetInfo) => {
    let isReceiveAssetOpted;
    if (assetInfo.isAssetOPTinRequired && walletDetails.isConnected) {
      isReceiveAssetOpted = await isOptedIntoAsset(walletDetails.address, assetInfo.assetID);
    }
    setSwapAssets({
      ...swapAssets,
      receive: {
        name: assetInfo.name,
        amount: 0,
        assetBalance: null,
        assetID: assetInfo.assetID,
        blockchain: assetInfo.blockchain,
        isAssetOPTinRequired: assetInfo.isAssetOPTinRequired,
        isAssetOpted:
          assetInfo.isAssetOPTinRequired && walletDetails.isConnected
            ? isReceiveAssetOpted
            : assetInfo.isAssetOpted,
      },
    });
  };

  const isEligibleforDisability = (accordionIndex) => {
    if (walletDetails.isConnected) {
      if (accordionIndex == 0) {
        return false;
      } else if (accordionIndex == 1 && basketType) {
        return false;
      } else if (
        accordionIndex == 2 &&
        basketType &&
        swapAssets.pay &&
        swapAssets.pay.name &&
        swapAssets.receive &&
        swapAssets.receive.name
      ) {
        return false;
      } else {
        return true;
      }
    } else if (!walletDetails.isConnected && accordionIndex == 0) {
      return false;
    } else {
      return true;
    }
  };

  async function swapToken() {
    let feeQuery;
    if (
      swapAssets.pay["name"] == "goUSD" ||
      swapAssets.pay["name"] == "goETH" ||
      swapAssets.pay["name"] == "goBTC"
    ) {
      feeQuery = swapAssets.receive.name;
    } else {
      feeQuery = swapAssets.pay.name;
    }

    try {
      if (
        swapAssets.pay["name"] === "WUSDT" ||
        swapAssets.pay["name"] === "WUSDC" ||
        swapAssets.receive.name === "WUSDT" ||
        swapAssets.receive.name === "WUSDC"
      ) {
        // Pass the necessary parameters to the function
        const id = await handleSwapTransaction(
          "goUSDX",
          swapAssets,
          payAmount,
          decimalPlace,
          feeQuery,
          POOL_APP_ID_QUERY,
          walletDetails,
          signTransactions,
          sendTransactions,
          setisLoading,
        );
        // Additional logic if needed for this specific case
        setisLoading(false);
        console.log("Successfully sent transaction. Transaction ID: ", id);
        toast.success(
          <div>
            Transaction successful! <br />
            Click to{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_NETWORK === "sandnet-v1"
                    ? `https://app.dappflow.org/explorer/transaction/${id}`
                    : process.env.REACT_APP_NETWORK === "testnet"
                    ? `https://testnet.algoexplorer.io/tx/${id}`
                    : `https://algoexplorer.io/tx/${id}`,
                  "_blank",
                );
              }}
            >
              view
            </span>{" "}
            on explorer.
          </div>,
          {
            position: "top-right",
            duration: 10000,
          },
        );
      } else {
        // For the other scenario
        const id = await handleSwapTransaction(
          basketType,
          swapAssets,
          payAmount,
          decimalPlace,
          feeQuery,
          POOL_APP_ID_QUERY,
          walletDetails,
          signTransactions,
          sendTransactions,
          setisLoading,
        );
        // Additional logic if needed for this scenario
        setisLoading(false);
        console.log("Successfully sent transaction. Transaction ID: ", id);
        toast.success(
          <div>
            Transaction successful! <br />
            Click to{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_NETWORK === "sandnet-v1"
                    ? `https://app.dappflow.org/explorer/transaction/${id}`
                    : process.env.REACT_APP_NETWORK === "testnet"
                    ? `https://testnet.algoexplorer.io/tx/${id}`
                    : `https://algoexplorer.io/tx/${id}`,
                  "_blank",
                );
              }}
            >
              view
            </span>{" "}
            on explorer.
          </div>,
          {
            position: "top-right",
            duration: 10000,
          },
        );
      }
    } catch (err) {
      setisLoading(false);
      console.error(err);
      let pactLink = {
        goUSD: "https://app.pact.fi/swap?pair=-USDC:31566704/GOUSD:672913181",
        goBTC: "https://app.pact.fi/swap?pair=GOBTC:386192725/WRAPPED+BTC:1058926737",
        goETH: "https://app.pact.fi/swap?pair=-GOETH:386195940/WRAPPED+ETHER:887406851",
      };
      if (err.response) {
        if (err.response.status == 400) {
          toast.error(
            <div>
              The trading price is currently outside of the baskets set limits, please try again
              later or use the pact pool{" "}
              <a href={pactLink[basketType]} className="underline" target="_blank">
                here
              </a>{" "}
            </div>,
            {
              position: "top-right",
              duration: 10000,
            },
          );
          return;
        }
      }
      toast.error(<div>There is some error, please try again.</div>, {
        position: "top-right",
        duration: 10000,
      });
      throw err;
    }
  }

  async function OPTinSingleAsset(from, to, amount, ASAID, txnType) {
    try {
      const algodClient = await initiateAlgodClient();
      const suggestedParams = await algodClient.getTransactionParams().do();
      setisLoading(true);
      const optInTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: from,
        to: to,
        amount: algosdk.algosToMicroalgos(amount),
        assetIndex: ASAID,
        suggestedParams: suggestedParams,
      });
      const encodedTransaction = algosdk.encodeUnsignedTransaction(optInTransaction);

      const signedTransactions = await signTransactions([encodedTransaction]);

      const waitRoundsToConfirm = 4;

      const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm);

      if (txnType == "pay") {
        setSwapAssets({
          ...swapAssets,
          pay: {
            isAssetOpted: true,
            amount: 0,
            assetBalance: null,
          },
        });
      }
      if (txnType == "receive") {
        setSwapAssets({
          ...swapAssets,
          receive: {
            isAssetOpted: true,
            amount: 0,
            assetBalance: null,
          },
        });
      }
      console.log("Successfully sent transaction. Transaction ID: ", id);
      setisLoading(false);
      toast.success(
        <div>
          Transaction successful! <br />
          Click to{" "}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              window.open(
                process.env.REACT_APP_NETWORK === "sandnet-v1"
                  ? `https://app.dappflow.org/explorer/transaction/${id}`
                  : process.env.REACT_APP_NETWORK === "testnet"
                  ? `https://testnet.algoexplorer.io/tx/${id}`
                  : `https://algoexplorer.io/tx/${id}`,
                "_blank",
              );
            }}
          >
            view
          </span>{" "}
          on explorer.
        </div>,
        {
          position: "top-right",
          duration: 10000,
        },
      );
    } catch (err) {
      setisLoading(false);
      console.log(err);
    }
  }

  const OPTinMutipleAssets = async (from, to, amount, ASA1, ASA2) => {
    const algodClient = await initiateAlgodClient();
    const suggestedParams = await algodClient.getTransactionParams().do();
    setisLoading(true);
    const optInTransaction1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA1,
      suggestedParams: suggestedParams,
    });
    const optInTransaction2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA2,
      suggestedParams: suggestedParams,
    });

    const txnsArray = [optInTransaction1, optInTransaction2];
    let txgroup = algosdk.assignGroupID(txnsArray);
    const txnsToSign = txnsArray.map((txn) => algosdk.encodeUnsignedTransaction(txn));

    const signedTransactions = await signTransactions(txnsToSign);

    const waitRoundsToConfirm = 4;

    const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm);
    setSwapAssets({
      ...swapAssets,
      pay: {
        ...swapAssets.pay,
        isAssetOpted: true,
      },
      receive: {
        ...swapAssets.receive,
        isAssetOpted: true,
      },
    });
    setisLoading(false);
    console.log("Successfully sent transaction. Transaction ID: ", id);
    toast.success(
      <div>
        Transaction successful! <br />
        Click to{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => {
            window.open(
              process.env.REACT_APP_NETWORK === "sandnet-v1"
                ? `https://app.dappflow.org/explorer/transaction/${id}`
                : process.env.REACT_APP_NETWORK === "testnet"
                ? `https://testnet.algoexplorer.io/tx/${id}`
                : `https://algoexplorer.io/tx/${id}`,
              "_blank",
            );
          }}
        >
          view
        </span>{" "}
        on explorer.
      </div>,
      {
        position: "top-right",
        duration: 10000,
      },
    );
  };

  async function checkASAPosition(assetName, amount) {
    try {
      const response = await axios.post(
        "https://algomint-analytics-gk6zp.ondigitalocean.app/checkAsaPosition",
        {
          asaName: assetName.toUpperCase(),
          asaAmount: amount * DECIMAL_PLACE[basketType],
        },
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const accordionData = [
    {
      name: "goBASKET TYPE",
      content: (
        <div className="flex flex-col space-y-4">
          <div>
            <AssetSelector
              title={"Select Basket"}
              options={goBaskets}
              updateContextHandler={updateSelectedgoBasket}
              ref={selectedBasketRef}
            />
          </div>
          <div
            className={`flex flex-row justify-center ${walletDetails.isConnected ? "hidden" : ""}`}
          >
            {walletDetails.isConnected ? null : (
              <Button
                name="CONNECT"
                classes={"w-72 bg-[#1BFED7] text-black dark:text-black font-extralight"}
                onClickHandler={() => {
                  setWalletDetails({
                    ...walletDetails,
                    isConnectWalletModalOpen: true,
                  });
                }}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      name: "ASSET SELECTION",
      content: (
        <SwapAssetsSelector
          payAssetsList={payAssetsOptions}
          receiveAssetsList={receiveAssetsOptions}
          onPayAssetSelectHandler={updateSelectedPayAsset}
          onReceiveAssetSelectHandler={updateSelectedReceiveAsset}
          resetInputs={resetInputs}
          setResetInputs={() => setResetInputs(false)}
          setPayAssetsOptions={setPayAssetsOptions}
          setReceiveAssetOptions={setReceiveAssetOptions}
          maxValue={maxValue}
          setMaxValue={setMaxValue}
          minValue={minValue}
          setMinValue={setMinValue}
          payAmount={payAmount}
          receiveAmount={receiveAmount}
          payAmountChangeHandler={payAmountChangeHandler}
          receiveAmountChangeHandler={receiveAmountChangeHandler}
          selectedPayAssetRef={selectedPayAssetRef}
          selectedReceiveAssetRef={selectedReceiveAssetRef}
        />
      ),
    },
    {
      name: "TRANSACTION DETAILS",
      content: (
        <TransactionCostDetails
          payAmount={payAmount}
          calculatedReceiveAmount={receiveAmount}
          slippageRate={slippageRate}
          setSlippageRate={setSlippageRate}
        />
      ),
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="w-130">
        {accordionData.map(({ name, content }, index) => {
          return (
            <Accordion
              key={index}
              accordionTitle={name}
              accordionContent={content}
              accordionItemIndex={index}
              toggleFirstStateByDefault={index == 0 ? true : false}
              toggleAccordion={isEligibleforDisability(index)}
              disabled={isEligibleforDisability(index)}
            />
          );
        })}
        {(() => {
          {
            if (isLoading) {
              return (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              );
            } else if (!isSmartContractOnline) {
              return (
                <TransactButton
                  title={
                    <>
                      {swapAssets.pay.name
                        ? swapAssets.pay.name.includes("go")
                          ? "Unwrapping"
                          : "Wrapping"
                        : "Wrapping"}{" "}
                      is temporarily paused, please try again in 10 minutes.
                      <br /> If the problem persists please contact support{" "}
                      <a
                        className="underline font-medium"
                        href="https://www.algomint.io/#support-form-wrapper"
                        target="_blank"
                        id="nun"
                      >
                        here{" "}
                      </a>
                      or via our discord{" "}
                      <a
                        className="underline font-medium"
                        href="https://discord.com/invite/rvq8G8Eycc"
                        target="_blank"
                      >
                        here
                      </a>
                    </>
                  }
                  classes={"bg-gray-600 text-white h-20 xs:text-xs sm:text-xs xs:px-2 sm:px-2"}
                />
              );
            } else if (walletDetails.isConnected) {
              if (walletDetails.isOfflineAccount) {
                return (
                  <TransactButton
                    title={`Please maintain minimum of 0.2 ALGOs in your wallet`}
                    classes={"bg-gray-600 text-white"}
                    disabled={true}
                    onClickHandler={() => console.log("Wallet Account is offline")}
                  />
                );
              } else if (walletDetails.balance < walletDetails.minBalance) {
                return (
                  <TransactButton
                    disabled={true}
                    title={`Please maintain a minimum of ${minBalance} ALGOs in your wallet`}
                    classes={"bg-gray-600 text-white"}
                    handleClick={() => console.log("Not enough ALGOs in wallet account")}
                  />
                );
              } else if (
                (!swapAssets.pay.name || !payAmount) &&
                (!swapAssets.receive.name || !receiveAmount)
              ) {
                return (
                  <TransactButton
                    title={`COMPLETE TRANSACTION`}
                    classes={"bg-gray-600 text-white"}
                    onClickHandler={() => {}}
                  />
                );
              } else if (
                swapAssets.pay.isAssetOPTinRequired &&
                !swapAssets.pay.isAssetOpted &&
                swapAssets.receive.isAssetOPTinRequired &&
                !swapAssets.receive.isAssetOpted
              ) {
                if (walletDetails.balance < 0.4) {
                  return (
                    <TransactButton
                      disabled={true}
                      title={`Please maintain a minimum of 0.4 ALGOs in your wallet`}
                      classes={"bg-gray-600 text-white"}
                      handleClick={() => console.log("Not enough ALGOs in wallet account")}
                    />
                  );
                } else {
                  return (
                    <TransactButton
                      title={`OPT IN`}
                      onClickHandler={async () =>
                        await OPTinMutipleAssets(
                          walletDetails.address,
                          walletDetails.address,
                          0,
                          swapAssets.pay.assetID,
                          swapAssets.receive.assetID,
                        )
                      }
                    />
                  );
                }
              } else if (swapAssets.pay.isAssetOPTinRequired && !swapAssets.pay.isAssetOpted) {
                if (walletDetails.balance < 0.2) {
                  return (
                    <TransactButton
                      disabled={true}
                      title={`Please maintain a minimum of 0.2 ALGOs in your wallet`}
                      classes={"bg-gray-600 text-white"}
                      handleClick={() => console.log("Not enough ALGOs in wallet account")}
                    />
                  );
                } else {
                  return (
                    <TransactButton
                      title={`OPT IN`}
                      onClickHandler={async () => {
                        await OPTinSingleAsset(
                          walletDetails.address,
                          walletDetails.address,
                          0,
                          swapAssets.pay.assetID,
                          "pay",
                        );
                      }}
                    />
                  );
                }
              } else if (
                swapAssets.receive.isAssetOPTinRequired &&
                !swapAssets.receive.isAssetOpted
              ) {
                if (walletDetails.balance < 0.2) {
                  return (
                    <TransactButton
                      disabled={true}
                      title={`Please maintain a minimum of 0.2 ALGOs in your wallet`}
                      classes={"bg-gray-600 text-white"}
                      handleClick={() => console.log("Not enough ALGOs in wallet account")}
                    />
                  );
                } else {
                  return (
                    <TransactButton
                      title={`OPT IN`}
                      onClickHandler={async () => {
                        await OPTinSingleAsset(
                          walletDetails.address,
                          walletDetails.address,
                          0,
                          swapAssets.receive.assetID,
                          "receive",
                        );
                      }}
                    />
                  );
                }
              } else if (swapAssets.pay.assetBalance < algosdk.algosToMicroalgos(payAmount)) {
                return (
                  <TransactButton
                    title={`Not enough ${swapAssets.pay.name} in your wallet`}
                    classes={"bg-gray-600 text-white"}
                    disabled={true}
                    onClickHandler={() => console.log("Not enough ASA in wallet account")}
                  />
                );
              } else {
                return (
                  <TransactButton
                    title={`GO!`}
                    disabled={maxValue || minValue ? true : false}
                    onClickHandler={async () => {
                      let isBasketAtMaxCapacity = await checkASAPosition(
                        swapAssets.pay.name,
                        payAmount,
                      );
                      if (isBasketAtMaxCapacity && isBasketAtMaxCapacity == "DENIED") {
                        toast.error(
                          <div>
                            The {swapAssets.pay.name} is at its max capacity in the basket.
                          </div>,
                          {
                            position: "top-right",
                            duration: 10000,
                          },
                        );
                        return;
                      }
                      let contractAssetBalance;
                      let poolLiq;
                      let { balA, balB } = await fetchPoolDetails(
                        swapAssets,
                        POOL_APP_ID[POOL_APP_ID_QUERY],
                        CREATOR_ADDRESS,
                        basketType,
                      );
                      if (swapAssets.pay.assetID == ASSET_IDS[basketType]) {
                        poolLiq = balB;
                      } else {
                        poolLiq = 1000000000000;
                      }
                      for (let i = 0; i < contractDetails.assets.length; i++) {
                        if (
                          contractDetails.assets[i]["asset-id"] ===
                          ASSET_IDS[swapAssets.receive.name]
                        ) {
                          contractAssetBalance = contractDetails.assets[i]["amount"];
                        }
                      }

                      if (
                        payAmount > contractAssetBalance &&
                        ASSET_IDS[swapAssets.pay.name] != ASSET_IDS[basketType]
                      ) {
                        console.log("Insufficient Contract Balance!");
                        toast.error(
                          <div>
                            The contract balance is insufficient at the moment. Please contact the
                            Algomint team{" "}
                            <a
                              className="underline font-medium"
                              href="https://www.algomint.io/#support-form-wrapper"
                              target="_blank"
                            >
                              here
                            </a>
                          </div>,
                          {
                            position: "top-right",
                            duration: 10000,
                          },
                        );
                        return;
                      } else if (payAmount * DECIMAL_PLACE[basketType] > poolLiq) {
                        toast.error(
                          <div>
                            Transaction Failed : Insufficient liquidity, please try a smaller amount
                            or contact the Algomint team{" "}
                            <a
                              className="underline font-medium"
                              href="https://www.algomint.io/#support-form-wrapper"
                              target="_blank"
                            >
                              here
                            </a>{" "}
                            for assistance.
                          </div>,
                          {
                            position: "top-right",
                            duration: 10000,
                          },
                        );
                        console.log("Insufficient Contract Liquidity Balance!");
                        return;
                      } else {
                        console.log("Minting/Redeeming!");
                        await swapToken();
                        // after confirmed transaction has happened
                        let payAssetASABalance = await getASABalance({
                          address: walletDetails.address,
                          assetId: swapAssets.pay.assetID,
                          txnType: "pay",
                        });
                        let receiveAssetASABalance = await getASABalance({
                          address: walletDetails.address,
                          assetId: swapAssets.receive.assetID,
                          txnType: "receive",
                        });
                        setSwapAssets({
                          ...swapAssets,
                          pay: {
                            ...swapAssets.pay,
                            assetBalance: payAssetASABalance.ASABalance,
                          },
                          receive: {
                            ...swapAssets.receive,
                            assetBalance: receiveAssetASABalance.ASABalance,
                          },
                        });
                        isContractStatusOnline(CONTRACT_ADDRESS[basketType]);
                      }
                    }}
                  />
                );
              }
            } else {
              return (
                <TransactButton
                  title={`PLEASE CONNECT WALLET`}
                  onClickHandler={() => {
                    setWalletDetails({
                      ...walletDetails,
                      isConnectWalletModalOpen: true,
                    });
                  }}
                  classes={"bg-gray-600 text-white"}
                />
              );
            }
          }
        })()}
      </div>
    </div>
  );
}

export default collapsecontainer;
