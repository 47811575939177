import { createContext, useState } from "react";

const WalletDetailsContext = createContext();

const WalletDetailsProvider = ({ children }) => {
  const [walletDetails, setWalletDetails] = useState({
    isConnected: false,
    isConnectWalletModalOpen: false,
    didConnectWalletFail: {
      failed: false,
      provider: "",
    },
    provider: "",
    address: null,
    balance: null,
    assets: [],
    isOfflineAccount: false, //since algo account has offline and online types
    minBalance: null,
    metaData: {},
  });

  return (
    <WalletDetailsContext.Provider
      value={{
        walletDetails,
        setWalletDetails,
      }}
    >
      {children}
    </WalletDetailsContext.Provider>
  );
};

export { WalletDetailsContext, WalletDetailsProvider };
