import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IMG } from "../../utils/misc";
import AssetsList from "../AssetsList";

export const AssetSelector = forwardRef(
  ({ title, label, options, updateContextHandler, resetInputs, setResetInputs }, _ref) => {
    const [toggleList, setToggleList] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const changeToggleListState = () => {
      setToggleList(!toggleList);
    };

    const hideList = () => {
      setToggleList(false);
    };

    useEffect(() => {
      if (resetInputs === true) {
        setSelectedItemIndex(null);
        setResetInputs();
      }
    }, [resetInputs]);

    useImperativeHandle(
      _ref,
      () => ({
        getCurrentIndex: () => {
          return selectedItemIndex;
        },
        setSelectedIndex: (name) => {
          return setSelectedItemIndex(name);
        },
        getItemDetailByIndex: (index) => {
          return options[index];
        },
        getOptionsLength: () => {
          return options.length;
        },
      }),
      [selectedItemIndex],
    );

    return (
      <div className="relative">
        {label ? <h6 className="dark:text-white text-black xs:text-xs text-sm">{label}</h6> : null}
        <div
          className="bg-white w-full h-12 flex justify-between items-center rounded-md shadow-md border dark:border-white focus:outline-double dark:bg-[#232328] cursor-pointer"
          onClick={() => changeToggleListState()}
        >
          <div className="text-black dark:text-white flex ml-4 space-x-2">
            {selectedItemIndex !== null ? (
              <div>
                {" "}
                <img
                  src={IMG(`${options[selectedItemIndex]["name"]}.svg`, "icons")}
                  alt="crypto-icon"
                  className="h-8 w-8"
                />
              </div>
            ) : null}

            <div>
              {selectedItemIndex !== null ? (
                options[selectedItemIndex]["displayName"]
              ) : (
                <span className="font-extralight xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                  {title}
                </span>
              )}
            </div>
          </div>
          <div>
            {" "}
            <BiChevronDown size={18} className="dark:text-white text-black mr-2" />
          </div>
        </div>
        {toggleList && options.length > 0 && (
          <AssetsList
            lists={options}
            changeToggleListState={changeToggleListState}
            onSelectItemHandler={setSelectedItemIndex}
            updateContextHandler={updateContextHandler}
            hideList={hideList}
          />
        )}
      </div>
    );
  },
);
