import React, { useContext } from "react";
import { SwapAssetsContext } from "../../context/swapAssets";
import { FEE_PERCENT } from "../../constants/constants";
import AdjustSlippagePercentage from "../AdjustSlippagePercentage";

export default function transactionCostDetails({
  payAmount,
  calculatedReceiveAmount,
  slippageRate,
  setSlippageRate,
}) {
  const { swapAssets } = useContext(SwapAssetsContext);

  let feeQuery;
  if (
    swapAssets.pay["name"] == "goUSD" ||
    swapAssets.pay["name"] == "goETH" ||
    swapAssets.pay["name"] == "goBTC"
  ) {
    feeQuery = swapAssets.receive.name;
  } else {
    feeQuery = swapAssets.pay.name;
  }

  return (
    <div className="w-full h-full flex flex-col space-y-4 xs:text-xs  sm:text-sm md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
      <div className="rate-wrapper flex xs:flex-col sm:flex-col  justify-between xs:text-center sm:text-center ">
        <div className="dark:text-white text-black">Rate</div>
        <div className="flex flex-col dark:text-[#1BFED7] text-black">
          <div>
            {calculatedReceiveAmount ? 1 / 1 : 0} {swapAssets.receive.name} per {payAmount ? 1 : 0}{" "}
            {swapAssets.pay.name}
          </div>
        </div>
      </div>
      <div className="fee-wrapper flex  justify-between xs:flex-col sm:flex-col xs:text-center sm:text-center">
        <div className="dark:text-white text-black">Fee {`(${FEE_PERCENT[feeQuery] * 100}%)`}</div>
        <div className="dark:text-[#1BFED7] text-black">
          {payAmount ? (payAmount * FEE_PERCENT[feeQuery]).toFixed(6) : 0} {swapAssets.pay.name}
        </div>
      </div>
      <div className="fee-wrapper flex  justify-between xs:flex-col sm:flex-col xs:text-center sm:text-center">
        <div className="dark:text-white text-black">Amount after fee</div>
        <div className="dark:text-[#1BFED7] text-black">
          {payAmount ? (payAmount - payAmount * FEE_PERCENT[feeQuery]).toFixed(6) : 0}{" "}
          {swapAssets.pay.name}
        </div>
      </div>
    </div>
  );
}
