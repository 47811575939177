import React from 'react'
import { IMG } from "../../utils/misc";
import PieChart from '../PieChart';

function goUSDBasketTable({title,data}) {
  return (
    <div className="flex flex-col w-full">
      <div
        className="flex justify-center space-x-2 m-4 xs:justify-center xs:space-y-2 xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md text-white text-center"
      >
        <div>
          <img
            src={IMG(`${title}.svg`, "icons")}
            alt="crypto-icon"
            className="h-6 w-6 sm:h-9 sm:w-9 xs:h-9 xs:w-9"
          />
        </div>
        <div className="dark:text-white hover:opacity-75  text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md align-middle">
          {title} Basket Composition
        </div>
      </div>
      <div className="flex flex-col xs:w-full sm:w-full">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 dark:bg-[#232328] bg-slate-100 rounded-xl">
            <div className="overflow-hidden">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b font-medium border-black dark:border-neutral-500 dark:text-white text-black">
                  <tr>
                    <th
                      scope="col"
                      className="text-left px-5 py-4 xs:py-0 sm:py-2 xs:px-2 sm:px-2  font-thin text-center xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
                    >
                      TOKEN
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-4 xs:py-0 sm:py-2 xs:px-2 sm:px-2 font-thin text-center xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
                    >
                      TOTAL VALUE $
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-4 xs:py-0 sm:py-2 xs:px-2 sm:px-2 font-thin text-center xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
                    >
                      MAX WEIGHT %
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-4 xs:py-0 sm:py-2 xs:px-6 sm:px-6 font-thin text-center xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
                    >
                      CURRENT WEIGHT %
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-4 xs:py-0 sm:py-2 xs:px-6 sm:px-6 font-thin text-center xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
                    >
                      PRICE $
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((ASA, index) => {
                    return (
                      <tr
                        className="border-b border-black dark:border-neutral-500 text-center"
                        key={index}
                      >
                        <td className="whitespace-nowrap flex justify-center space-x-2  px-6 py-4 font-medium">
                          <img
                            src={IMG(`${ASA}.svg`, "icons")}
                            alt="ASA-icon"
                            className="h-8 w-8"
                          />
                          <div className="flex flex-col">
                            <div className="text-left dark:text-white xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                              {ASA}
                            </div>
                            <div className="text-xs text-left text-[#AEAEAE] xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md">
                              ${ASA} - {data[ASA]["ASA_ID"]}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                          $
                          {Number(data[ASA]["TOTAL_VALUE"]).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                          {data[ASA]["MAX_WEIGHT"]}%
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                          {Number(data[ASA]["CURRENT_WEIGHT"]).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                          $
                          {data[ASA]["TRADING_PRICE"].toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className=" dark:text-white text-black flex flex-col mt-2">
                <div className="flex justify-center">
                  <PieChart
                    series={Object.values(data).map((el) => el["CURRENT_WEIGHT"])}
                    labels={Object.keys(data)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default goUSDBasketTable