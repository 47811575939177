import React,{useState,useEffect} from "react";
import AlgomintLiquidity from "../AlgomintLiquidity";
import GoAssets from "../GoAssets";
import AlgomintStats from "../AlgomintStats";
import BasketTable from "../BasketTable";
import Spinner from "../Spinner";
import axios from "axios";
import "./Analytics.css";

function analytics() {
  const [analyticsData, setAnalyticsData] = useState({});
    useEffect(() => {
      getAnalyticsSummary();
    }, []);

    async function getAnalyticsSummary() {
      try {
        const response = await axios.get(
          "https://algomint-analytics-gk6zp.ondigitalocean.app/analytics"
        );
        if (response.status === 200) {
          setAnalyticsData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  return (
    <div className="analytics-wrapper flex flex-col space-y-4 justify-center align-center w-full">
      {Object.keys(analyticsData).length > 0 ? (
        <div className="flex flex-col space-y-8 mb-8 md:mx-12 lg:mx-24 xl:mx-32 2xl:mx-32">
          <GoAssets data={analyticsData["goAssets"]} />
          <AlgomintStats data={analyticsData["algomintStats"]} />
          <AlgomintLiquidity data={analyticsData["algomintLiquidity"]} />
          <BasketTable title={"goUSD"} data={analyticsData["goUSDbasket"]} />
          <BasketTable title={"goETH"} data={analyticsData["goETHbasket"]} />
          <BasketTable title={"goBTC"} data={analyticsData["goBTCbasket"]} />
        </div>
      ) : (
        <h1 className="flex justify-center">
          <Spinner />
        </h1>
      )}
    </div>
  );
}

export default analytics;
