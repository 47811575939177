import axios from "axios";
import initiateAlgodClient from "./algodClient";
import { ASSET_IDS } from "../constants/constants";

export async function readGlobalState(client, addr, appId) {
  const output = {};
  const accountInfoResponse = await client.accountInformation(addr).do();
  for (let i = 0; i < accountInfoResponse["created-apps"].length; i++) {
    if (accountInfoResponse["created-apps"][i].id == appId) {
      for (
        let n = 0;
        n < accountInfoResponse["created-apps"][i]["params"]["global-state"].length;
        n++
      ) {
        const variable = accountInfoResponse["created-apps"][i]["params"]["global-state"][n];
        const name = Buffer.from(variable.key, "base64").toString();
        output[name] = variable.value;
      }
    }
  }
  return output;
}

async function fetchPoolDetails(swapAssets, poolId, creatorAddr, basketType) {
  let poolLiq;
  let balanceA;
  let balanceB;
  if (process.env.REACT_APP_NETWORK === "sandnet-v1") {
    const algodClient = await initiateAlgodClient();
    const poolState = await readGlobalState(algodClient, creatorAddr, poolId);
    const balanceA_ = poolState["A"];
    const balanceB_ = poolState["B"];
    balanceA = balanceA_.uint;
    balanceB = balanceB_.uint;
  } else {
    let response = await axios.get(
      `https://mainnet-idx.algonode.cloud/v2/applications?application-id=${poolId}`,
    );
    let globalState = response.data.applications[0].params["global-state"];

    const balanceAKey = "QQ==";
    const balanceBKey = "Qg==";
    for (let i = 0; i < globalState.length; i++) {
      if (globalState[i].key === balanceAKey) {
        balanceA = globalState[i].value.uint;
      }
    }
    for (let i = 0; i < globalState.length; i++) {
      if (globalState[i].key === balanceBKey) {
        balanceB = globalState[i].value.uint;
      }
    }
    if (swapAssets.pay.assetID == ASSET_IDS[basketType]) {
      poolLiq = balanceB;
    } else {
      poolLiq = balanceA;
    }
  }

  return { balA: balanceA, balB: balanceB };
}
export { fetchPoolDetails };
