import algosdk from "algosdk";
import initiateAlgodClient from "../utils/algodClient";
import EncodeBytes from "../utils/encodeBytes";
import {
  POOL_APP_ID,
  ASSET_IDS,
  CONTRACT_ADDRESS,
  TREASURY_ADDRESS,
  CREATOR_ADDRESS,
  APP_ID,
  FEE_PERCENT,
  DECIMAL_PLACE,
  POOL_CONTRACT_ADDR,
  POOL_ESCROW_ADDR,
  ESCROW_APP_ID,
  FARM_APP_ID,
  ARGS,
} from "../constants/constants";
export async function handleSwapTransaction(
  basketType,
  swapAssets,
  payAmount,
  decimalPlace,
  feeQuery,
  POOL_APP_ID_QUERY,
  walletDetails,
  signTransactions,
  sendTransactions,
  setisLoading,
) {
  const algodClient = await initiateAlgodClient();
  const suggestedParams = await algodClient.getTransactionParams().do();
  const fee = Math.ceil(payAmount * decimalPlace * FEE_PERCENT[[feeQuery]]);
  const payAmountAfterFee = Math.floor(payAmount * decimalPlace - fee);
  setisLoading(true);
  let appArgs = [];
  let foreignAssets = [];
  let accountsArray = [POOL_CONTRACT_ADDR[feeQuery], POOL_ESCROW_ADDR[feeQuery]];
  if (swapAssets.pay.assetID == ASSET_IDS[basketType]) {
    appArgs.push(EncodeBytes("redeem"));
    appArgs.push(EncodeBytes(ARGS[swapAssets.receive.name]));
    foreignAssets.push(
      ASSET_IDS[swapAssets.receive.name],
      ASSET_IDS[`${swapAssets.receive.name}LP`],
      ASSET_IDS[swapAssets.pay.name],
    );
  } else {
    appArgs.push(EncodeBytes("mint"));
    appArgs.push(EncodeBytes(ARGS[swapAssets.pay.name]));
    foreignAssets.push(
      ASSET_IDS[basketType],
      ASSET_IDS[`${swapAssets.pay.name}LP`],
      ASSET_IDS[swapAssets.pay.name],
    );
  }
  const foreignApps = [
    POOL_APP_ID[POOL_APP_ID_QUERY],
    FARM_APP_ID[POOL_APP_ID_QUERY],
    ESCROW_APP_ID[POOL_APP_ID_QUERY],
  ];
  const transferTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: walletDetails.address,
    to: CONTRACT_ADDRESS[basketType],
    amount: payAmountAfterFee,
    suggestedParams: suggestedParams,
    assetIndex: ASSET_IDS[swapAssets.pay.name],
  });

  const transferFeeTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: walletDetails.address,
    to: TREASURY_ADDRESS,
    amount: fee,
    suggestedParams: suggestedParams,
    assetIndex: ASSET_IDS[swapAssets.pay.name],
  });

  const callTxn = algosdk.makeApplicationNoOpTxnFromObject({
    from: walletDetails.address,
    suggestedParams: suggestedParams,
    appIndex: APP_ID[basketType],
    appArgs: appArgs,
    foreignAssets: foreignAssets,
    foreignApps: foreignApps,
    accounts: accountsArray,
  });

  callTxn.fee = 60000;
  // Sign transactions using the connected wallet provider.
  const txnsArray = [transferTxn, transferFeeTxn, callTxn];
  let txgroup = algosdk.assignGroupID(txnsArray);
  const txnsToSign = txnsArray.map((txn) => algosdk.encodeUnsignedTransaction(txn));

  const signedTransactions = await signTransactions(txnsToSign);

  const waitRoundsToConfirm = 4;

  const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm);

  return id;
}
