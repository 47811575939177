import axios from "axios";
import { APP_ID } from "../constants/constants";

async function isContractStatusOnline() {
  try {
    const url =
      process.env.REACT_APP_NETWORK === "testnet"
        ? `https://mainnet-idx.algonode.cloud/v2/applications?application-id=${APP_ID.goUSD}`
        : "https://mainnet-idx.algonode.cloud/v2/applications?application-id=1071722337";

    const response = await axios.get(url);

    const globalState = response.data.applications[0].params["global-state"];
    let keyValue = Buffer.from("STATUS").toString("base64");
    let status;
    for (let i = 0; i < globalState.length; i++) {
      if (globalState[i].key === keyValue) {
        status = globalState[i].value.uint;
      }
    }
    console.log(`Contract is ${status === 1 ? "online" : "offline"}!`);
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default isContractStatusOnline;
