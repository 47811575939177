import { createContext, useState } from "react";

const ContractContext = createContext();

const ContractProvider = ({ children }) => {
  const [contractDetails, setContractDetails] = useState({
    algoBalance: null,
    assets: [],
  });

  return (
    <ContractContext.Provider
      value={{
        contractDetails,
        setContractDetails,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export { ContractContext, ContractProvider };
