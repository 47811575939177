import React, { useContext } from "react";
import AlgomintLogo from "../../images/icons/algomint-logo.svg";
import { ThemeContext } from "../../context/theme";
import "./GradientBar.css";

export default function gradientBar() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div
      className={`${
        darkMode ? "gradient-wrapper" : "gradient-wrapper-flipped"
      } xs:hidden sm:hidden md:hidden`}
    >
      <a href="https://www.algomint.io" target="_blank">
        <img src={AlgomintLogo} alt="Algomint logo" className="mx-2 mt-3 h-12 w-12" />
      </a>{" "}
    </div>
  );
}
