export const socialIcons = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="none"
      >
        <path
          className="fill-black dark:fill-white"
          d="m28 10.237-2.25.375 1.5-1.875-2.625.75c-3.375-3.75-8.625.375-7.125 3.75-6 0-9-4.5-9-4.5s-2.25 3.375 1.5 6l-2.25-.75c0 2.25 1.5 3.75 4.125 4.5H9.25c1.5 3 4.125 3 4.125 3S11.125 23.362 7 23.362c12.375 6 19.875-5.25 18.75-11.25L28 10.237Z"
        />
        <circle
          cx="16.5"
          cy="16.5"
          r="16"
          className="dark:stroke-white stroke-black"
          strokeOpacity=".5"
        />
      </svg>
    ),
    link: "https://twitter.com/Algomint_io",
    name: "Twitter",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="none"
      >
        <path
          className="fill-black dark:fill-white"
          d="M9.372 11.264a.784.784 0 0 0-.252-.658L7.252 8.339V8h5.798l4.482 9.905L21.472 8H27v.339L25.403 9.88a.472.472 0 0 0-.177.452v11.334a.473.473 0 0 0 .177.452l1.56 1.542V24h-7.844v-.339l1.616-1.58c.159-.16.159-.207.159-.451v-9.162l-4.492 11.494h-.606l-5.23-11.494v7.704c-.043.323.064.65.29.884l2.101 2.568v.338H7v-.338l2.1-2.568a1.03 1.03 0 0 0 .272-.884v-8.908Z"
        />
        <circle
          cx="16.5"
          cy="16.5"
          r="16"
          className="dark:stroke-white stroke-black"
          strokeOpacity=".5"
        />
      </svg>
    ),
    link: "https://medium.com/algomint",
    name: "Medium",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="none"
      >
        <path
          className="fill-black dark:fill-white"
          d="m24.938 9.603-3.169 14.944c-.24 1.054-.862 1.316-1.748.82l-4.829-3.558-2.33 2.241c-.257.258-.472.473-.97.473l.348-4.917 8.948-8.086c.39-.346-.085-.539-.605-.191L9.521 18.294l-4.763-1.49c-1.035-.324-1.054-1.036.216-1.533l18.628-7.177c.863-.324 1.617.192 1.336 1.51v-.001Z"
        />
        <circle
          cx="16.5"
          cy="16.5"
          r="16"
          className="dark:stroke-white stroke-black"
          strokeOpacity=".5"
        />
      </svg>
    ),
    link: "https://t.me/Algomint2",
    name: "Telegram",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="none"
      >
        <path
          className="fill-black dark:fill-white"
          d="M14.686 12.272h3.343v1.665c.481-.957 1.716-1.818 3.57-1.818 3.557 0 4.401 1.906 4.401 5.404V24h-3.6v-5.68c0-1.992-.481-3.116-1.707-3.116-1.7 0-2.407 1.21-2.407 3.114V24h-3.6V12.272ZM8.513 23.847h3.6V12.119h-3.6v11.728Zm4.116-15.552a2.277 2.277 0 0 1-.678 1.622 2.33 2.33 0 0 1-3.274 0A2.293 2.293 0 0 1 8 8.296c0-.61.243-1.192.678-1.623a2.325 2.325 0 0 1 3.273 0c.434.43.678 1.014.678 1.623Z"
          clipRule="evenodd"
        />
        <circle
          cx="16.5"
          cy="16.5"
          r="16"
          className="dark:stroke-white stroke-black"
          strokeOpacity=".5"
        />
      </svg>
    ),
    link: "https://www.linkedin.com/company/algomint/",
    name: "LinkedIn",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        fill="none"
      >
        <path
          className="fill-black dark:fill-white"
          d="M23.931 8.493a14.755 14.755 0 0 0-4.07-1.492.054.054 0 0 0-.038.006.069.069 0 0 0-.028.03c-.176.37-.371.853-.507 1.23a12.934 12.934 0 0 0-4.573 0c-.152-.42-.324-.831-.515-1.23a.07.07 0 0 0-.027-.03.055.055 0 0 0-.038-.006 14.655 14.655 0 0 0-4.071 1.492.055.055 0 0 0-.026.027c-2.593 4.574-3.304 9.035-2.955 13.44.001.021.012.042.026.055 1.51 1.32 3.198 2.329 4.994 2.982a.054.054 0 0 0 .039 0 .065.065 0 0 0 .03-.029c.386-.62.728-1.273 1.023-1.96a.085.085 0 0 0-.012-.088.062.062 0 0 0-.023-.016 10.444 10.444 0 0 1-1.56-.878.072.072 0 0 1-.029-.043.085.085 0 0 1 .004-.055.073.073 0 0 1 .019-.028c.105-.093.21-.19.31-.288a.058.058 0 0 1 .03-.015.054.054 0 0 1 .034.005c3.273 1.765 6.817 1.765 10.05 0a.056.056 0 0 1 .035-.006.06.06 0 0 1 .032.016c.1.098.205.195.31.288a.072.072 0 0 1 .02.028.086.086 0 0 1-.003.072.07.07 0 0 1-.022.026 9.712 9.712 0 0 1-1.561.876.062.062 0 0 0-.023.017.076.076 0 0 0-.015.028.09.09 0 0 0 .004.06c.3.686.644 1.342 1.021 1.961a.065.065 0 0 0 .031.028.054.054 0 0 0 .04 0c1.798-.65 3.49-1.659 5-2.98a.068.068 0 0 0 .019-.024.082.082 0 0 0 .008-.031c.417-5.094-.699-9.518-2.958-13.44a.052.052 0 0 0-.01-.017.042.042 0 0 0-.015-.01ZM13.684 19.277c-.986 0-1.798-1.068-1.798-2.379 0-1.312.797-2.38 1.798-2.38 1.009 0 1.812 1.077 1.797 2.38 0 1.311-.796 2.38-1.797 2.38Zm6.645 0c-.985 0-1.798-1.068-1.798-2.379 0-1.312.797-2.38 1.798-2.38 1.009 0 1.814 1.077 1.797 2.38 0 1.311-.788 2.38-1.797 2.38Z"
        />
        <circle
          cx="16.5"
          cy="16.5"
          r="16"
          className="dark:stroke-white stroke-black"
          strokeOpacity=".5"
        />
      </svg>
    ),
    link: "https://discord.com/invite/rvq8G8Eycc",
    name: "Discord",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        version="1.0"
        viewBox="0 0 471 471"
      >
        <path
          d="M218.5 2c-44.4 3.3-86.9 19.3-123.3 46.4-13.7 10.2-36.6 33.1-46.8 46.8C-3.2 164.4-13 254.4 22.5 333.1c11.4 25.4 26.6 47.2 47.9 68.5 45.4 45.3 101.5 68.6 165.6 68.6s120.2-23.3 165.6-68.6c37.1-37.1 58.7-80 66.5-132.1 4.2-28 2.4-63.3-4.7-91C439.4 84.4 359.6 14.3 264 3.1c-13-1.5-33.3-2-45.5-1.1zm41 22c23.6 2.7 48.5 9.9 69.9 20.4 22.5 11 38.9 22.8 57.2 41 18.2 18.3 30 34.8 41 57.1C442 172 449 202.7 449 236.2c0 57.3-21.8 109.8-62.4 150.4C346 427.2 293.5 449 236.2 449c-73.5 0-139.1-35.9-180.2-98.5-16.1-24.7-28.5-59.8-32-91-2.2-20-1-45.7 3.1-66.5 10.8-54.1 44.8-104.2 91.8-135.2 26.4-17.5 60.9-30.1 92.1-33.7 10.7-1.2 37.9-1.3 48.5-.1z"
          className="fill-black/50 dark:fill-white/50"
        />
        <path
          d="M128 153.4c5.4 5.1 107.2 89.6 108 89.6.8 0 103.7-85.5 108.5-90.1 1.9-1.9.2-1.9-108.5-1.9H125.5l2.5 2.4zM108 235.6c0 67.2.1 70.6 1.8 69.2.9-.8 19.5-16.3 41.2-34.3 21.7-18 40.1-33.3 40.8-34 1-.9-7.3-8.3-39.4-35.1-22.4-18.6-41.6-34.5-42.6-35.2-1.7-1.3-1.8 1.1-1.8 69.4zM321.7 199.7c-22.9 19.1-41.7 35.2-41.6 35.8 0 .5 18.8 16.6 41.7 35.7l41.7 34.7.3-35.5c.1-19.6.1-51.3 0-70.5l-.3-35-41.8 34.8zM166.9 284.8l-42.6 35.7 55.8.3c30.8.1 81 .1 111.8 0l55.9-.3-42.5-35.5c-23.4-19.5-42.8-35.4-43-35.4-.3.1-6.3 5-13.4 10.9L236 271.2l-11.8-9.8c-6.4-5.3-12.4-10.3-13.2-11-1.3-1-8.3 4.5-44.1 34.4z"
          className="fill-black dark:fill-white "
        />
      </svg>
    ),
    link: "https://www.algomint.io/#support-form-wrapper",
    name: "Support",
  },
];
