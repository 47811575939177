import React, { useContext, useEffect } from "react";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { SwapAssetsContext } from "../../context/swapAssets";

function SwitchAssetButton({
  payAssetsList,
  setPayAssetsOptions,
  setReceiveAssetOptions,
  selectedPayAssetRef,
  selectedReceiveAssetRef,
  disable,
  receiveAssetsList,
  payAmountChangeHandler,
  payAmount,
}) {
  const { swapAssets, setSwapAssets } = useContext(SwapAssetsContext);

  useEffect(() => {
    const recalculateAmounts = async () => {
      await payAmountChangeHandler(payAmount);
    };
    recalculateAmounts();
  }, [swapAssets]);
  return (
    <div className="flex justify-center" id="switchButton">
      <button
        type="button"
        disabled={disable}
        className="border-1 p-2 rounded-full shadow-md bg-slate-200 hover:bg-slate-300 dark:bg-slate-700 dark:border-shaded-dark dark:hover:bg-slate-600 transition ease-in-out hover:scale-125 duration-200"
        onClick={() => {
          const temporaryPayAsset = swapAssets.pay;
          const temporaryReceiveAsset = swapAssets.receive;
          selectedPayAssetRef.current.setSelectedIndex(
            selectedReceiveAssetRef.current.getCurrentIndex(),
          );
          selectedReceiveAssetRef.current.setSelectedIndex(
            selectedPayAssetRef.current.getCurrentIndex(),
          );
          setReceiveAssetOptions(payAssetsList);
          setPayAssetsOptions(receiveAssetsList);

          setSwapAssets({
            ...swapAssets,
            pay: temporaryReceiveAsset,
            receive: temporaryPayAsset,
          });
        }}
      >
        <HiOutlineSwitchVertical size={22} className="dark:text-[#1BFED7] text-black" />
      </button>
    </div>
  );
}

export default SwitchAssetButton;
