import React,{useState} from 'react';
import Chart from "react-apexcharts";

function PieChart({series,labels}) {
    const [options, setOptions] = useState({
      series: series,
      options: {
        chart: {
          type: "donut",
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
    });
  return <Chart options={options.options} series={options.series} type="donut" width="500" />;
}

export default PieChart