import React, { useRef, useEffect } from "react";
import { IMG } from "../../utils/misc";
function AssetsList({
  lists,
  changeToggleListState,
  onSelectItemHandler,
  updateContextHandler,
  hideList,
}) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hideList();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <ul
      className={`Assetslist-wrapper h-fit w-96 md:w-96 xs:w-72 sm:w-72 fixed z-50  mt-1 flex flex-col dark:ring-1 dark:ring-slate-500 items-center shadow-md  rounded-md  bg-white dark:bg-[#232328]`}
      ref={wrapperRef}
    >
      {lists &&
        lists.map((item, index) => {
          return (
            <li
              key={index}
              className={`w-full p-4 cursor-pointer rounded-md dark:hover:bg-zinc-800 hover:bg-gray-100`}
              onClick={() => {
                if (item.disable) {
                  return;
                }
                onSelectItemHandler(index);
                changeToggleListState();
                updateContextHandler(item);
              }}
            >
              <div className="text-black dark:text-white flex items-center ml-4 space-x-2">
                <div>
                  <img
                    src={`${IMG(`${item.name}.svg`, "icons")}`}
                    alt="crypto-icon"
                    className="h-8 w-8"
                  />
                </div>
                <div>{item.displayName}</div>
                <div className="text-sm">{item.description}</div>
              </div>
            </li>
          );
        })}
    </ul>
  );
}

export default AssetsList;
