import algosdk from "algosdk";

async function initiateIndexerClient() {
  const baseServer = "https://mainnet-idx.algonode.cloud/v2";
  const port = "";
  const token = "";

  const indexerClient = new algosdk.Indexer(token, baseServer, port);

  return indexerClient;
}

export default initiateIndexerClient;
