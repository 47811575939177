import React, { useContext } from "react";
import algosdk from "algosdk";
import { WalletDetailsContext } from "../../context/walletDetails";
import { SwapAssetsContext } from "../../context/swapAssets";
import { DECIMAL_PLACE } from "../../constants/constants";

function MaxButton({ onChangeAmount, category, maxValue, poolMaxValue }) {
  const { walletDetails } = useContext(WalletDetailsContext);
  const { swapAssets, basketType } = useContext(SwapAssetsContext);
  const decimalPlace = DECIMAL_PLACE[basketType];
  return (
    <div className="rounded-md">
      <button
        type="button"
        disabled={!walletDetails.isConnected ? true : maxValue ? true : false}
        className={`${
          maxValue
            ? "text-white bg-red-500 border-red-500 dark:bg-red-500 dark:border-red-500 hover:none dark:hover:none"
            : "bg-slate-50 dark:bg-[#232328]  dark:hover:bg-slate-600 hover:bg-slate-200"
        }  dark:bg-[#232328]  rounded-md flex items-center px-3 py-2 min-w-fit xs:text-sm  duration-150`}
        onClick={() => {
          if (
            category === "pay"
              ? swapAssets.pay.assetBalance / decimalPlace > poolMaxValue
              : swapAssets.receive.assetBalance / decimalPlace > poolMaxValue
          ) {
            onChangeAmount(poolMaxValue);
          } else {
            onChangeAmount(
              category === "pay"
                ? swapAssets.pay.assetBalance / decimalPlace
                : swapAssets.receive.assetBalance / decimalPlace,
            );
          }
        }}
      >
        <span
          className={`dark:${maxValue ? "text-white" : "text-[#1BFED7]"} font-semibold text-xs`}
        >
          MAX
        </span>
      </button>
    </div>
  );
}

export default MaxButton;
