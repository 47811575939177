import React, { useState, useRef, useEffect } from "react";
import { socialIcons } from "../../constants/socialIcons";
import "./MoreOptionsNavDropDown.css";

function MoreOptionsNavDropDown() {
  const [toggleOptions, setToggleOptions] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const changeToggleOptionsState = () => {
    setToggleOptions(!toggleOptions);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="MoreOptionsNavDropDown-wrapper relative" onClick={changeToggleOptionsState}>
      ...
      {toggleOptions && (
        <div
          className="MoreOptionsNavDropDown-options-wrapper fixed flex flex-col z-50  dark:bg-[#232328] bg-slate-100 space-y-2 p-6"
          ref={wrapperRef}
        >
          {" "}
          {socialIcons.map((item, i) => {
            return (
              <div
                className="flex space-x-2 cursor-pointer hover:opacity-75"
                key={i}
                onClick={() => {
                  window.open(item.link, "_blank");
                }}
              >
                {item.icon}
                <div className="xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md align-bottom mt-1">
                  {item.displayName}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MoreOptionsNavDropDown;
