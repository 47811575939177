import React from "react";

function Button({ name, redirectTo, classes, onClickHandler }) {
  return (
    <button
      onClick={() => {
        if (redirectTo) {
          return window.open(redirectTo, "_blank");
        }
        onClickHandler();
      }}
      className={`${name}-btn border text-sm font-semibold   hover:bg-[#1BFED7] hover:text-black h-9 w-28 border-[#1BFED7] rounded-full dark:text-[#1BFED7] dark:hover:text-black text-black ${
        classes ? classes : ""
      }`}
    >
      {name}
    </button>
  );
}

export default Button;
