import React, { useEffect, useContext } from "react";
import NavItems from "../NavItem";
import Button from "../Button";
import { socialIcons } from "../../constants/socialIcons";
import "./NavDrawer.css";
import { NavigationDrawerContext } from "../../context/navigationDrawer";

const NavDrawer = () => {
  const { toggle } = useContext(NavigationDrawerContext);
  useEffect(() => {
    toggle
      ? (document.getElementById("sidenav").style.height = "100%")
      : (document.getElementById("sidenav").style.height = "0");
  }, [toggle]);

  return (
    <div
      id="sidenav"
      className={`sidenav h-screen w-full text-white ${
        toggle ? "block" : "hidden"
      }`}
    >
      <div className="nav-items">
        <NavItems />
      </div>
      <div className="socials">
        {socialIcons.map((item, i) => {
          return (
            <span
              key={i}
              className="cursor-pointer"
              onClick={() => {
                window.open(item.link, "_blank");
              }}
            >
              {item.icon}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default NavDrawer;
