import algosdk from "algosdk";
import axios from "axios";

async function fetchWalletDetails(_addr) {
  try {
    let response = await axios.get(
      ` ${process.env.REACT_APP_ALGORAND_API_URL}/v2/accounts/${_addr}`,
    );
    if (response.status === 200) {
      let account = await response.data.account;
      const { address, amount, assets } = account;
      return {
        address: address,
        amount: algosdk.microalgosToAlgos(amount),
        assets: assets,
        isOfflineAccount: false,
      };
    }
  } catch (err) {
    console.log(err,"err");
    if (err.response.data.message.includes("no accounts found for address")) {
      return {
        address: null,
        amount: null,
        assets: null,
        isOfflineAccount: true,
      };
    }
  }
}
export { fetchWalletDetails };
