import { ASSET_IDS } from "../constants/constants";
export const goBaskets = [
  {
    name: "goUSD",
    displayName: "goUSD",
    blockchain: "Algorand",
    isAssetOPTinRequired: true,
    assetID: ASSET_IDS["goUSD"],
    disable: false,
  },
  {
    name: "goBTC",
    displayName: "goBTC",
    blockchain: "Algorand",
    isAssetOPTinRequired: true,
    assetID: ASSET_IDS["goBTC"],
    disable: false,
  },
  {
    name: "goETH",
    displayName: "goETH",
    blockchain: "Algorand",
    isAssetOPTinRequired: true,
    assetID: ASSET_IDS["goETH"],
    disable: false,
  },
];
