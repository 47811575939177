//A little annoying, but if you want to test on sandbox, replace the commented out import to target the testnet JSON files.
import usdBasketConfig from "./usdBasketConfig.json";
// import usdBasketConfig from "./usdBasketConfig-sandbox.json";
import btcBasketConfig from "./btcBasketConfig.json";
// import btcBasketConfig from "./btcBasketConfig-sandbox.json";
import ethBasketConfig from "./ethBasketConfig.json";
///////////////////////////////////////////////////////////////
export const MAX_VALUE = 250000;
// export const MIN_VALUE = 0.001;
export const MIN_SLIPPAGE_PERCENT = 0;
export const MAX_SLIPPAGE_PERCENT = 1;

export const ASSET_IDS = {
  USDC: usdBasketConfig.usdcId,
  XUSD: usdBasketConfig.xusdId,
  WUSDT: usdBasketConfig.wusdtId,
  WUSDC: usdBasketConfig.wusdcId,
  goUSD: usdBasketConfig.goUsdId,
  goUSDX: usdBasketConfig.goUsdId,
  USDT: usdBasketConfig.usdtId,
  WDAI: usdBasketConfig.daiId,
  USDCLP: usdBasketConfig.usdcLpId,
  WUSDCLP: usdBasketConfig.wusdcLpId,
  WUSDTLP: usdBasketConfig.wusdtLpId,
  XUSDLP: usdBasketConfig.xusdLpId,
  USDTLP: usdBasketConfig.usdtLpId,
  WDAILP: usdBasketConfig.daiLpId,
  goBTC: btcBasketConfig.goBtcId,
  pBTC: btcBasketConfig.pBtcId,
  pBTCLP: btcBasketConfig.pBtcLpId,
  wBTC: btcBasketConfig.wBtcId,
  wBTCLP: btcBasketConfig.wBtcLpId,
  x: btcBasketConfig.busdId,
  xLP: btcBasketConfig.busdLpId,
  wETH: ethBasketConfig.wETHId,
  goETH: ethBasketConfig.goETHId,
  wETHLP: ethBasketConfig.wETHLpId,
  pETHLP: ethBasketConfig.pETHLpId,
  ETHX: ethBasketConfig.busdId,
  ETHXLP: ethBasketConfig.busdLpId,
};

export const MIN_VALUE = {
  goUSD: 0.001,
  goBTC: 0.000013,
  goETH: 0.000013,
};

export const FEE_PERCENT = {
  USDC: 0.002,
  XUSD: 0.002,
  USDT: 0.002,
  WUSDC: 0.002,
  WUSDT: 0.002,
  WDAI: 0.002,
  wBTC: 0.002,
  wETH: 0.002,
};

export const APP_ID = {
  goUSD: usdBasketConfig.basketId,
  goUSDX: 1245781352,
  goBTC: btcBasketConfig.basketId,
  goETH: ethBasketConfig.basketId,
};

export const POOL_APP_ID = {
  USDC: 885102197,
  USDT: 1081978547,
  WUSDC: 1242543233,
  WUSDT: 1242550428,
  XUSD: 1081974468,
  WDAI: 1081976691,
  wETH: 1058935016,
  wBTC: 1058934586,
};

export const FARM_APP_ID = {
  USDC: 1150765030,
  WUSDC: 1242927816,
  WUSDT: 1242927914,
  USDT: 1150764970,
  XUSD: 1083532721,
  WDAI: 1081976691,
  wETH: 1150764423,
  wBTC: 1150764376,
};

export const POOL_CONTRACT_ADDR = {
  USDC: "CWELFRXFDWLRY6ZO6QET3AB7L3PKZD3KVDJWZULYIJYOARHUTAWN2LZT2Y",
  USDT: "63JOMD2CR7JHE7OBMGIMYTT53S3OA53Z3PJQHX6E4K7QPEXMO3W3VWFZ5A",
  WUSDC: "GXS356GPGTDWIFVESJGOPLOEBCWWIVOWUMBF4XKOYNKNCJQ2YBMFNGFHRQ",
  WUSDT: "AXH4PSCCFS2PHH5NTCCW6J22BOXGMNEWNTRPBZFJLQVDH3W6BYGQTHXSEM",
  WDAI: "EOTQCJH5MTJAG6HC2ZJRRXA4RZ5TIEGUUAUT5P2PRZ5W4QFWPCBLA4JYRM",
  wETH: "W7QITGWCMOAIAV6EX3JDSHDL37LSF76M3LHTL6QD2WYMM7SGAGR3IUSLR4",
  wBTC: "TBOOS5PQDECS22YRG4OTLGHNVODRIC2R2U24ITYIL6YYD7NTKSKCPZCOC4",
  XUSD: "LDA5CCEHEF5XV7LSI2WWT27LSFPTLGCEA2Q2KN2CC2PJML42KMJOWMQVPU",
};

export const POOL_ESCROW_ADDR = {
  USDC: "S3VJZN4AXUP3IZKE4O7TUN6LRIEYNJCMXQSFP6DPGUKU6YYK2VLN2W7DXA",
  WUSDC: "HGKK45FRG5XLUE232664RC7HXQEF52YIUXHRYXJYZW5GT2SWUNTI4M3FOQ",
  WUSDT: "MEZOSEZWLVZYSOBCZYUYSKL7LS5JTIV5G2TBX6M3FFINUKP3BJVVA2S5T4",
  USDT: "CX7ICRT4HRKHZSSYYMW765AKSBDH3XJBQZ2DXN32DCWTD2732RVHR25Y5Q",
  WDAI: "EOTQCJH5MTJAG6HC2ZJRRXA4RZ5TIEGUUAUT5P2PRZ5W4QFWPCBLA4JYRM",
  wETH: "IUTNDPUTZ5MKFFIZ5L7MG35I5WXS64LLJKVXRTJNJISBRPOTOAM3DA65CY",
  wBTC: "MGGJI6CKRMIEN7CGXY2SK3KTPRCXW4SNPDPN4G7RWON4DB4262G4IRFHXE",
  XUSD: "ZFWCUIFTE5CKDEFBL3KBQA5OIVLFOG2D7MN7UUK7ZKIW2QVOLAYBR2L5WE",
};

export const ESCROW_APP_ID = {
  USDC: 1151342922,
  USDT: 1151364054,
  WUSDC: 1245802664,
  WUSDT: 1245811485,
  XUSD: 1151366153,
  WDAI: 1081976691,
  wETH: 1151403071,
  wBTC: 1151447481,
};

export const DECIMAL_PLACE = {
  goUSDX: 1000000,
  goUSD: 1000000,
  goBTC: 100000000,
  goETH: 100000000,
};

export const CONTRACT_ADDRESS = {
  goUSD: usdBasketConfig.basketAddr,
  goUSDX: "457DJFDBWZUXHYWZ2A3ADHEDOIUOYE3TZWMHADM42NLZY5NNGV5DSB76NY",
  goBTC: btcBasketConfig.basketAddr,
  goETH: ethBasketConfig.basketAddr,
};
// export const CONTRACT_ADDRESS = usdBasketConfig.basketAddr;
export const TREASURY_ADDRESS = usdBasketConfig.treasuryAddr;
export const ALGORAND_API_URL = process.env.REACT_APP_ALGORAND_API_URL;
//Leaving this as an env variable for now, as this is for sandbox only..
export const CREATOR_ADDRESS = process.env.REACT_APP_CREATOR_ADDRESS; //This is for reading global state for sandbox.. may need to add a seperate function that uses the indexer
export const FAUCET_APP_ID = {
  goUSD: usdBasketConfig.faucetId,
  goBTC: btcBasketConfig.faucetId,
  goETH: ethBasketConfig.faucetId,
};

export const FAUCET_CONTRACT_ADDRESS = {
  goUSD: usdBasketConfig.treasuryAddr,
  goBTC: btcBasketConfig.treasuryAddr,
  goETH: ethBasketConfig.treasuryAddr,
};

export const ARGS = {
  INIT: "INIT",
  goUSD: "goUSD",
  USDC: "USDC",
  WUSDT: "WUST",
  WUSDC: "WUSD",
  XUSD: "XUSD",
  BUSD: "BUSD",
  USDT: "USDT",
  MINT: "MINT",
  REDEEM: "REDEEM",
  wBTC: "wBTC",
  goBTC: "goBTC",
  wETH: "wETH",
  goETH: "goETH",
};
