import React from 'react'
import { IMG } from "../../utils/misc";

function goAssets({data}) {
  return (
    <div className='flex flex-col w-full'>
      <h6 className="text-white text-center my-3">Algomint Assets</h6>
      <div className="dark:bg-[#232328] cursor-pointer bg-slate-100 rounded-xl flex justify-center xs:flex-col">
        {Object.keys(data).map((asset, index) => {
          return (
            <div
              key={index}
              className="flex space-x-2 m-4 xs:justify-center xs:space-y-2 xs:text-xs sm:text-xs md:text-md lg:text-md xl:text-md 2xl:text-md"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_NETWORK === "sandnet-v1"
                    ? `https://app.dappflow.org/explorer/asset/${data[asset]["ASA_ID"]}`
                    : process.env.REACT_APP_NETWORK === "testnet"
                    ? `https://testnet.algoexplorer.io/asset/${data[asset]["ASA_ID"]}`
                    : `https://algoexplorer.io/asset/${data[asset]["ASA_ID"]}`,
                  "_blank",
                );
              }}
            >
              <div>
                {" "}
                <img
                  src={IMG(`${asset}.svg`, "icons")}
                  alt="crypto-icon"
                  className="h-6 w-6 sm:h-9 sm:w-9 xs:h-9 xs:w-9"
                />
              </div>
              <div className="dark:text-white hover:opacity-75  text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md align-middle">
                {asset} {data[asset]["ASA_ID"]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default goAssets