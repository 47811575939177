import MoreOptionsNavDropDown from "../components/MoreOptionsNavDropDown";

export const navigationItems = [
  {
    title: "BASKET",
    link: "/",
    isExternalLink: false,
    displayArrowIcon: false,
  },
  {
    title: "ANALYTICS",
    link: "/analytics",
    isExternalLink: false,
    displayArrowIcon: false,
  },
  {
    title: "FAUCET",
    link: "/faucet",
    isExternalLink: false,
    displayArrowIcon: false,
  },
  {
    title: "BRIDGE",
    link: "https://app.algomint.io/",
    isExternalLink: true,
    displayArrowIcon: true,
  },
  {
    title: "...",
    link: null,
    isExternalLink: true,
    displayArrowIcon: false,
    component: <MoreOptionsNavDropDown />,
  },
];
