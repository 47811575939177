import React, { useEffect, useState, useContext } from "react";
import algosdk from "algosdk";
import initiateAlgodClient from "../../utils/algodClient";
import { ASSET_IDS, FAUCET_APP_ID } from "../../constants/constants";
import EncodeBytes from "../../utils/encodeBytes";
import { useWallet } from "@txnlab/use-wallet";
import { useNavigate, Link } from "react-router-dom";
import TransactButton from "../TransactButton";
import { isOptedIntoAsset } from "../../utils/assetOPT-inValidator";
import { WalletDetailsContext } from "../../context/walletDetails";
import Spinner from "../Spinner";
import { IoIosArrowBack } from "react-icons/io";
import toast from "react-hot-toast";

function DispenseFaucets() {
  const { signTransactions, sendTransactions } = useWallet();
  const { walletDetails, setWalletDetails } = useContext(WalletDetailsContext);
  const [isFaucetAssetsOpted, setIsFaucetAssetsOpted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const dispenseHandler = async () => {
    setIsLoading(true);
    await getFaucets(walletDetails.address);
    setIsLoading(false);
  };

  async function getFaucets(address) {
    try {
      const algodClient = await initiateAlgodClient();
      const params = await algodClient.getTransactionParams().do();

      const accounts = undefined;
      const foreignApps = undefined;
      const foreignAssets = [
        ASSET_IDS["USDC"],
        ASSET_IDS["goUSD"],
        ASSET_IDS["USDT"],
        ASSET_IDS["BUSD"],
      ];

      const foreignAsset2 = [
        ASSET_IDS["wBTC"],
        ASSET_IDS["goBTC"],
        ASSET_IDS["pBTC"],
        ASSET_IDS["x"],
      ];
      const foreignAsset3 = [
        ASSET_IDS["wETH"],
        ASSET_IDS["goETH"],
        ASSET_IDS["pETH"],
        ASSET_IDS["ETHX"],
      ];
      //This is the goUSD token id
      // const closeRemainderTo = undefined;
      // const revocationTarget = undefined;
      // const note = undefined;
      const appArgs = [];
      appArgs.push(EncodeBytes("FUND"));

      let callContract1 = algosdk.makeApplicationNoOpTxn(
        address,
        params,
        FAUCET_APP_ID.goUSD,
        appArgs,
        accounts,
        foreignApps,
        foreignAssets,
      );
      let callContract2 = algosdk.makeApplicationNoOpTxn(
        address,
        params,
        FAUCET_APP_ID.goBTC,
        appArgs,
        accounts,
        foreignApps,
        foreignAsset2,
      );

      let callContract3 = algosdk.makeApplicationNoOpTxn(
        address,
        params,
        FAUCET_APP_ID.goETH,
        appArgs,
        accounts,
        foreignApps,
        foreignAsset3,
      );

      const txnsArray = [callContract1, callContract2, callContract3];
      let txgroup = algosdk.assignGroupID(txnsArray);

      const txnsToSign = txnsArray.map((txn) => algosdk.encodeUnsignedTransaction(txn));

      const signedTransactions = await signTransactions(txnsToSign);

      const waitRoundsToConfirm = 4;

      const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm);

      // console.log the completed Transaction
      console.log(`Fund faucets transaction ${id} confirmed in round.}`);
      toast.success(
        <div>
          Transaction successful! <br />
          Click to{" "}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              window.open(
                process.env.REACT_APP_NETWORK === "sandnet-v1"
                  ? `https://app.dappflow.org/explorer/transaction/${id}`
                  : process.env.REACT_APP_NETWORK === "testnet"
                  ? `https://testnet.algoexplorer.io/tx/${id}`
                  : `https://algoexplorer.io/tx/${id}`,
                "_blank",
              );
            }}
          >
            view
          </span>{" "}
          on explorer.
        </div>,
        {
          position: "top-right",
          duration: 10000,
        },
      );
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      throw err;
    }
  }

  useEffect(() => {
    const checkOPTStatus = async () => {
      setIsLoading(true);
      await checkDispenseASAOPTinStatus();
      setIsLoading(false);
    };
    if (walletDetails.isConnected) {
      checkOPTStatus();
    }
  }, [walletDetails.isConnected]);

  const checkDispenseASAOPTinStatus = async () => {
    let isUSDCOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.USDC);
    let isgoUSDOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.goUSD);
    let isUSDTOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.USDT);
    let isBUSDOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.BUSD);
    let iswBTCOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.wBTC);
    let ispBTCOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.pBTC);
    let isgoBTCOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.goBTC);
    let isXBTCOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.x);
    let isgoETHOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.goETH);
    let iswETHOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.wETH);
    let ispETHOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.pETH);
    let isXETHOpted = await isOptedIntoAsset(walletDetails.address, ASSET_IDS.pETH);

    if (
      isUSDCOpted &&
      isgoUSDOpted &&
      isUSDTOpted &&
      isBUSDOpted &&
      iswBTCOpted &&
      ispBTCOpted &&
      isgoBTCOpted &&
      isXBTCOpted &&
      isgoETHOpted &&
      iswETHOpted &&
      ispETHOpted &&
      isXETHOpted
    ) {
      setIsFaucetAssetsOpted(true);
    } else {
      setIsFaucetAssetsOpted(false);
    }
  };

  const OPTinFaucetsAssets = async (
    from,
    to,
    amount,
    ASA1,
    ASA2,
    ASA3,
    ASA4,
    ASA5,
    ASA6,
    ASA7,
    ASA8,
    ASA9,
    ASA10,
    ASA11,
    ASA12,
  ) => {
    const algodClient = await initiateAlgodClient();
    const suggestedParams = await algodClient.getTransactionParams().do();
    setIsLoading(true);
    const optInTransaction1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA1,
      suggestedParams: suggestedParams,
    });
    const optInTransaction2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA2,
      suggestedParams: suggestedParams,
    });
    const optInTransaction3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA3,
      suggestedParams: suggestedParams,
    });
    const optInTransaction4 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA4,
      suggestedParams: suggestedParams,
    });
    const optInTransaction5 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA5,
      suggestedParams: suggestedParams,
    });
    const optInTransaction6 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA6,
      suggestedParams: suggestedParams,
    });
    const optInTransaction7 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA7,
      suggestedParams: suggestedParams,
    });
    const optInTransaction8 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA8,
      suggestedParams: suggestedParams,
    });
    const optInTransaction9 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA9,
      suggestedParams: suggestedParams,
    });
    const optInTransaction10 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA10,
      suggestedParams: suggestedParams,
    });
    const optInTransaction11 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA11,
      suggestedParams: suggestedParams,
    });
    const optInTransaction12 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosdk.algosToMicroalgos(amount),
      assetIndex: ASA12,
      suggestedParams: suggestedParams,
    });

    const txnsArray = [
      optInTransaction1,
      optInTransaction2,
      optInTransaction3,
      optInTransaction4,
      optInTransaction5,
      optInTransaction6,
      optInTransaction7,
      optInTransaction8,
      optInTransaction9,
      optInTransaction10,
      optInTransaction11,
      optInTransaction12,
    ];
    let txgroup = algosdk.assignGroupID(txnsArray);
    const txnsToSign = txnsArray.map((txn) => algosdk.encodeUnsignedTransaction(txn));

    const signedTransactions = await signTransactions(txnsToSign);

    const waitRoundsToConfirm = 4;

    const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm);
    setIsFaucetAssetsOpted(true);
    console.log("Successfully sent transaction. Transaction ID: ", id);
    toast.success(
      <div>
        Transaction successful! <br />
        Click to{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => {
            window.open(
              process.env.REACT_APP_NETWORK === "sandnet-v1"
                ? `https://app.dappflow.org/explorer/transaction/${id}`
                : process.env.REACT_APP_NETWORK === "testnet"
                ? `https://testnet.algoexplorer.io/tx/${id}`
                : `https://algoexplorer.io/tx/${id}`,
              "_blank",
            );
          }}
        >
          view
        </span>{" "}
        on explorer.
      </div>,
      {
        position: "top-right",
        duration: 10000,
      },
    );
    setIsLoading(false);
  };

  return (
    <div className="flex justify-center pt-10 xs:px-4 ">
      <div className="border-none rounded-md py-10 px-8 w-full max-w-md flex flex-col">
        <div className="text-gray-400 mb-3">
          <Link to="/">
            <button className="flex flex-row items-center text-sm hover:underline">
              <IoIosArrowBack /> Back to Swapping Tokens
            </button>
          </Link>
        </div>
        <h1 className="text-2xl font-semibold pb-6 dark:text-white">Fund your Algorand Account</h1>

        <div className="my-5 dark:text-gray-200">
          <p className="text-sm">
            Enjoy some test-net assets from our faucet dispenser to try out the new Basket-v2 App!
            🎉 into your account.{" "}
          </p>
        </div>
        {walletDetails.isConnected ? (
          walletDetails.isOfflineAccount ? (
            <TransactButton
              title={`Please maintain minimum of 0.2 ALGOs in your wallet`}
              classes={"bg-gray-600 text-white"}
              disabled={true}
              onClickHandler={() => console.log("Wallet Account is offline")}
            />
          ) : isLoading ? (
            <div className="flex flex-row justify-center">
              <Spinner />
            </div>
          ) : isFaucetAssetsOpted ? (
            <TransactButton
              title={"Dispense"}
              onClickHandler={() => {
                dispenseHandler();
              }}
            />
          ) : (
            <TransactButton
              title={"OPT In"}
              onClickHandler={async () => {
                await OPTinFaucetsAssets(
                  walletDetails.address,
                  walletDetails.address,
                  0,
                  ASSET_IDS.USDC,
                  ASSET_IDS.goUSD,
                  ASSET_IDS.USDT,
                  ASSET_IDS.BUSD,
                  ASSET_IDS.wBTC,
                  ASSET_IDS.pBTC,
                  ASSET_IDS.goBTC,
                  ASSET_IDS.x,
                  ASSET_IDS.wETH,
                  ASSET_IDS.pETH,
                  ASSET_IDS.goETH,
                  ASSET_IDS.ETHX,
                );
              }}
            />
          )
        ) : (
          <TransactButton
            title={"CONNECT WALLET"}
            onClickHandler={() => {
              setWalletDetails({
                ...walletDetails,
                isConnectWalletModalOpen: true,
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DispenseFaucets;
