import React from "react";
import { useInitializeProviders, WalletProvider, PROVIDER_ID } from "@txnlab/use-wallet";
import { Toaster } from "react-hot-toast";
import Layout from "./components/Layout";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "./context/theme";
import { SwapAssetsProvider } from "./context/swapAssets";
import { ContractProvider } from "./context/contract";
import { WalletDetailsProvider } from "./context/walletDetails";
import CollapseContainer from "./components/CollapseContainer";
import WalletConnectionModal from "./components/WalletConnectionModal";
import Analytics from "./components/Analytics";
import { Routes, Route } from "react-router-dom";
import GradientBar from "./components/GradientBar";
import Faucet from "./components/Faucet";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { PeraWalletConnect } from "@perawallet/connect";
import { DaffiWalletConnect } from "@daffiwallet/connect";
import { Web3ModalSign } from "@web3modal/sign-html";
import MyAlgoConnect from "@randlabs/myalgo-connect";

function App() {
  const walletProviders = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.MYALGO, clientStatic: MyAlgoConnect },
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
      {
        id: PROVIDER_ID.WALLETCONNECT,
        clientStatic: Web3ModalSign,
        clientOptions: {
          projectId: "5c6a98081213d3edcc926a8f26abf43f",
          metadata: {
            name: "Basket v2",
            description: "Algomint Dapp",
            url: "https://basket.algomint.io/",
            icons: ["https://walletconnect.com/walletconnect-logo.png"],
          },
        },
      },
    ],
    nodeConfig: {
      network: "mainnet",
      nodeServer: "https://mainnet-api.algonode.cloud",
      nodeToken: "",
      nodePort: "",
    },
  });
  return (
    <>
      {" "}
      <ContractProvider>
        <WalletProvider value={walletProviders}>
          <WalletDetailsProvider>
            <ThemeProvider>
              <div className="flex">
                <GradientBar />
                <Layout>
                  <Helmet>
                    <title>Algomint - Basket</title>
                  </Helmet>
                  <WalletConnectionModal />
                  <SwapAssetsProvider>
                    <Routes>
                      <Route exact path="/" element={<CollapseContainer />} />
                      <Route exact path="/analytics" element={<Analytics />} />
                      <Route exact path="/faucet" element={<Faucet />} />
                    </Routes>
                  </SwapAssetsProvider>
                </Layout>
              </div>
            </ThemeProvider>
          </WalletDetailsProvider>
        </WalletProvider>
      </ContractProvider>
      <Toaster />
    </>
  );
}

export default App;
