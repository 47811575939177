import algosdk from "algosdk";

async function initiateAlgodClient() {
  const baseServer = "https://mainnet-api.algonode.cloud";
  const port = "";
  const token = "";

  const algodClient = new algosdk.Algodv2(token, baseServer, port);

  return algodClient;
}
export default initiateAlgodClient;
