import React from 'react'

function AlgomintStats({data}) {
  return (
    <div className="flex flex-col w-full">
      <h6 className="text-white text-center my-3">Algomint Asset Stats</h6>
      <div className="dark:bg-[#232328] bg-slate-100 rounded-xl flex justify-center xs:flex-col sm:flex-col  align-center">
        {Object.keys(data).map((totalDataTitle, index) => {
          return (
            <div
              key={index}
              className="flex space-x-2  m-4 xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md"
            >
              <div className="dark:text-white text-black font-thin xs:text-center sm:text-center">
                {totalDataTitle == "TOTAL_TXNS"
                  ? null
                  : "Total Circulating Value:"}
              </div>
              <div className="text-[#1BFED7] xs:text-center sm:text-center xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md align-middle">
                {/* {totalDataTitle !== "TOTAL_TXNS" ? "$" : null} */}
                {totalDataTitle == "TOTAL_TXNS"
                  ? null
                  : Number(data[totalDataTitle]).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AlgomintStats