import React from 'react'
import { IMG } from "../../utils/misc";

function AlgomintLiquidity({data}) {
  return (
    <div className="flex flex-col w-full">
      <h6 className="text-white text-center my-3">Basket Liquidity Deployment</h6>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 dark:bg-[#232328] bg-slate-100 rounded-xl">
          <div className="overflow-hidden">
            <table className="min-w-full text-left font-light">
              <thead className="border-b font-medium border-black dark:border-neutral-500 dark:text-white text-black">
                <tr>
                  <th
                    scope="col"
                    className="px-8 xs:px-6 py-4 sm:px-6 xs:py-0 sm:py-0 font-thin text-center xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md"
                  >
                    PROTOCOL
                  </th>
                  {/* <th
                    scope="col"
                    className="px-16 xs:px-6 sm:px-6 py-4 font-thin text-center xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md"
                  >
                    TOTAL TVL
                  </th> */}
                  <th
                    scope="col"
                    className="px-20 xs:px-6 sm:px-6  xs:py-0 sm:py-0 py-4 font-thin text-center xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md"
                  >
                    ALGOMINT CONTRIBUTED TVL
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((platform, index) => {
                  return (
                    <tr
                      className="border-b border-black dark:border-neutral-500 text-center"
                      key={index}
                    >
                      <td className="whitespace-nowrap flex justify-center font-medium">
                        <img
                          src={IMG(`${platform}.svg`, "icons")}
                          alt="crypto-icon"
                          className="h-16 w-16 xs:h-12 xs:w-12 sm:h-12 sm:w-12"
                        />
                      </td>
                      {/* <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                        ${data[platform]["TVL"]}
                      </td> */}
                      <td className="whitespace-nowrap px-6 py-4 dark:text-white text-black xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
                        $
                        {Number(data[platform]["ALGOMINT_CONTRIBUTED"]).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlgomintLiquidity;