import { createContext, useState } from "react";
const SwapAssetsContext = createContext();

const SwapAssetsProvider = ({ children }) => {
  const [basketType, setBasketType] = useState(null);
  const [swapAssets, setSwapAssets] = useState({
    pay: {
      amount: 0,
      name: null,
      isAssetOPTinRequired: false,
      assetID: null,
      isAssetOpted: false,
      blockchain: null,
      assetBalance: null,
    },
    receive: {
      amount: 0,
      name: null,
      isAssetOPTinRequired: false,
      assetID: null,
      isAssetOpted: false,
      blockchain: null,
      assetBalance: null,
    },
  });

  return (
    <SwapAssetsContext.Provider
      value={{
        basketType,
        setBasketType,
        swapAssets,
        setSwapAssets,
      }}
    >
      {children}
    </SwapAssetsContext.Provider>
  );
};

export { SwapAssetsContext, SwapAssetsProvider };
